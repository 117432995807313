import React from 'react'
import 'intro.js/introjs.css'
import { Steps } from 'intro.js-react'
import PropTypes from 'prop-types'
import { Button, Header, Modal } from 'semantic-ui-react'

export default function Tour (props) {
  const [welcomeOpen, setWelcomeOpen] = React.useState(false)
  const [tourEnabled, setTourEnabled] = React.useState(false)

  const storageKey = 'pro.contactform.app.tour'
  const revision = 1

  React.useEffect(() => {
    const tourState = localStorage.getItem(storageKey)
    if (!tourState || tourState < revision) {
      setWelcomeOpen(true)
    }
  }, [])

  const startTour = () => {
    setWelcomeOpen(false)
    setTourEnabled(true)
  }

  const dismissTour = () => {
    localStorage.setItem(storageKey, revision)
    setWelcomeOpen(false)
    setTourEnabled(false)
  }

  const WelcomeModal = <Modal
    onClose={() => setWelcomeOpen(false)}
    onOpen={() => setWelcomeOpen(true)}
    open={welcomeOpen}
    size='tiny'
    data-cy='cf-tour-welcome'
  >
    <Header>Tour</Header>
    <Modal.Content>
      <p>Got time for a 30s form editor tour?</p>
    </Modal.Content>
    <Modal.Actions>
      <Button color='green' onClick={() => startTour()}>
        Yes
      </Button>
      <Button color='grey' onClick={() => setWelcomeOpen(false)}>
        Later
      </Button>
      <Button color='red' onClick={() => dismissTour()}>
        Turn off
      </Button>
    </Modal.Actions>
  </Modal>

  const steps = [
    {
      element: document.querySelector('[data-cf-tour="preview"]'),
      intro: 'Any changes made to your contact form widget will be reflected in this live preview automatically.'
    },
    {
      element: document.querySelector('[data-cf-tour="colour"]'),
      intro: 'You can change the widget theme to match your website'
    },
    {
      element: document.querySelector('[data-cf-tour="icon"]'),
      intro: 'You can also change the widget trigger icon'
    },
    {
      intro: 'Further help is available via the link in the footer'
    }
  ]
  return <>
    {WelcomeModal}
    {tourEnabled
      ? <Steps
        enabled={true}
        steps={steps}
        initialStep={0}
        onExit={() => true}
        onComplete={() => dismissTour()}
        onBeforeChange={props.onBeforeChange}
        options={{ hideNext: false }}
      />
      : null}
  </>
}

Tour.propTypes = {
  onBeforeChange: PropTypes.func
}
