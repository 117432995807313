import React, { useEffect, useState } from 'react'
import { Button, Header, Icon, Modal, Grid, Dropdown } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getAvailableEnticeTypesAPI, getEnticeTypesAPI, updateEntice } from '../../../actions/form-actions'
import _ from 'lodash-es'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styles from './styles.less'
import UpgradeTooltip from '../../common/UpgradeTooltip'

import NewEnticeItemModal from '../new-entice-item'

export default function EnticeModal (props) {
  const [open, setOpen] = React.useState(false)
  const [openNewEnticeItemModal, setOpenNewEnticeItemModal] = React.useState(false)
  const dispatch = useDispatch()
  const { availableEnticeItems, currentForm } = useSelector((state) => state.form)
  const [enticeSet, setEnticeSet] = useState([])
  const [compareSet, setCompareSet] = useState([])
  const [busy, setBusy] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const { abilities } = useSelector((state) => state.subscription)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    if (props.open) {
      setOpen(true)
    }
    const { id: formId } = currentForm
    dispatch(getAvailableEnticeTypesAPI())
    dispatch(getEnticeTypesAPI(formId)).then(() => {
      setEnticeSet([...currentForm.enticeSet])
      setCompareSet([...currentForm.enticeSet])
    })
  }, [props.open])

  useEffect(() => {
    setIsChanged(!_.isEqual(enticeSet, compareSet))
  }, [JSON.stringify(enticeSet), JSON.stringify(compareSet)])

  const _open = (state) => {
    setOpen(state)
    props.handleOpen(state)
  }

  function handleDragEnd (event) {
    const { active, over } = event
    if (active && over) {
      if (active.id !== over.id) {
        setEnticeSet((items) => {
          return arrayMove(items, active.id - 1, over.id - 1)
        })
      }
    }
  }

  function handleEnticeSave (_event) {
    setBusy(true)
    const { id: formId } = currentForm
    const reduced = enticeSet.map(item => {
      const { value, parent } = item
      return {
        value,
        parent
      }
    })

    if (isChanged) {
      dispatch(updateEntice(formId, reduced)).then(() => {
        _open(false)
      }).catch((e) => {
        setBusy(false)
      })
    } else {
      _open(false)
    }
  }

  function handleAddNewEnticeItem (_event) {
    setOpenNewEnticeItemModal(true)
  }

  function handleEnticeUpdate (newItem) {
    setEnticeSet((enticeSet) => {
      newItem.id = enticeSet.length + 1
      enticeSet.push(newItem)
      return enticeSet
    })
    setOpenNewEnticeItemModal(false)
  }

  function handleEnticeItemDelete (id) {
    setEnticeSet(enticeSet.filter((item) => item.id !== id))
  }

  function renderEnticeItem (item) {
    const enticeItem = availableEnticeItems[item.parent]
    if (!enticeItem) return null

    const name = availableEnticeItems[item.parent].name
    switch (name) {
      case 'Message':
        return <div className={styles.enticeMessage}>{item.value}</div>
      case 'Link': {
        const urlLabelPair = item.value.split(',')
        return <a className={styles.enticeAction} href={window.atob(urlLabelPair[0])}>
          <div>{window.atob(urlLabelPair[1])}</div>
        </a>
      }
    }
  }

  const { abilities: planAbilities } = abilities
  const enticeEditorEnabled = planAbilities.includes('updateEnticeTypes')

  return (
    <Modal
      onClose={() => _open(false)}
      onOpen={() => _open(true)}
      open={open}
      size='small'
      className="disabledCardTooltip"
    >
      {!enticeEditorEnabled && <UpgradeTooltip />}
      <Header>Entice</Header>
      <Modal.Content scrolling>
        {!enticeEditorEnabled && <div className={styles.disabledEnticeDialogOverlay}>
          <div className={styles.disabledEnticeDialogLock}><Icon name='lock' /></div>
        </div>}
        <Grid columns={2} divided>
          <Grid.Column width={6}>
            <p>How often should Entice appear?</p>
            <div style={{ padding: '1em 0' }}>
              <Dropdown selection fluid disabled={true} options={[
                { key: 0, text: 'Everytime until dismissed', value: 0 }
              ]} value={0} />
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            <div className={styles.enticeContainer}>
              <div className={styles.enticeItems}>
                <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                  <SortableContext items={enticeSet.map((_enticeItem, index) => (index + 1))} strategy={verticalListSortingStrategy}>
                    {enticeSet.length < 1 && 'Save to reset to default entice items'}
                    <div className={styles.entice}>
                      {enticeSet.map((enticeItem, index) => {
                        return <SortableItem key={`${enticeItem.id}-${index}`} id={(index + 1)} withoutSegment>
                          <div className={styles.enticeItem}>
                            {renderEnticeItem(enticeItem)}
                            <Button icon size='mini' basic onClick={() => handleEnticeItemDelete(enticeItem.id)}>
                              <Icon name='delete' />
                            </Button>
                          </div>
                        </SortableItem>
                      })}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
              <div className={styles.widgetContainer}>
                <div className={styles.widgetIcon}>
                  <div>&nbsp;</div>
                </div></div>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleAddNewEnticeItem()} disabled={!enticeEditorEnabled}>
          <Icon name='plus' /> Insert item
        </Button>
        <Button onClick={() => handleEnticeSave()} disabled={busy || !enticeEditorEnabled} loading={busy}>
          <Icon name='checkmark' /> {isChanged ? 'Save' : 'Close'}
        </Button>
      </Modal.Actions>
      {openNewEnticeItemModal && <NewEnticeItemModal open={openNewEnticeItemModal} setOpen={setOpenNewEnticeItemModal} enticeSet={availableEnticeItems} update={handleEnticeUpdate} />}
    </Modal>
  )
}

EnticeModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  handleOpen: PropTypes.func
}

function SortableItem (props) {
  const sortableObj = {
    id: props.id
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable(sortableObj)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative'
  }

  return (
    <div ref={setNodeRef} style={style} >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Icon color='grey' {...attributes} {...listeners} name='ellipsis vertical' size='large' style={{ margin: 0, cursor: 'grab' }} />
        {props.children}
      </div>
    </div>
  )
}

SortableItem.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number
}
