import { Card, Button } from 'semantic-ui-react'
import React from 'react'
import styles from './styles.less'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'

class InstallationCards extends React.Component {
  handleCopy (event) {
    event.target.select()
  }

  render () {
    return (
      <Card.Group>
        <Card fluid>
          <Card.Content>
            <Card.Header>Code Snippet</Card.Header>
            <Card.Description>
              Add the following code snippet to your site within the {'<head></head>'} section.
            </Card.Description>
          </Card.Content>
          <Card.Content>
            <textarea onClick={this.handleCopy}
              className={styles.code}
              readOnly
              value={`<script src="https://embed.contactform.pro/cf.js" data-cf="${this.props.id}" async></script>`}
              rows={4}>
            </textarea>
          </Card.Content>
          <Card.Content>
            <Button onClick={() => copy(`<script src="https://embed.contactform.pro/cf.js" data-cf="${this.props.id}" async></script>`)}>
              Copy
            </Button>
          </Card.Content>

        </Card>

        <Card fluid>
          <Card.Content>
            <Card.Header>Wordpress</Card.Header>
            <Card.Description>
              Use our Wordpress plugin and you&apos;ll just need to copy your form key and save it within the <b>ContactForm.Pro</b> page within Wordpress Settings.
            </Card.Description>
          </Card.Content>
          <Card.Content>
            <textarea onClick={this.handleCopy}
              className={styles.code}
              readOnly
              value={this.props.id}
              rows={1}>

            </textarea>
          </Card.Content>
          <Card.Content>
            <Button onClick={() => copy(this.props.id)}>
              Copy
            </Button>
          </Card.Content>

        </Card>
      </Card.Group>
    )
  }
}

InstallationCards.propTypes = {
  id: PropTypes.string
}

export default InstallationCards
