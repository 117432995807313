import ActionTypes from './action-types'
import http from './http'

export function createSessionAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.POSTv2('v1/billing/session').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.BILLING_SESSION,
          payload: response.data
        })

        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getSubscriptionAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/billing/subscription').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_GET,
          payload: response
        })

        if (response.plan && response.plan.abilities) {
          dispatch({
            type: ActionTypes.ACCOUNT.SUBSCRIPTION_ABILITIES_GET,
            payload: response.plan.abilities
          })
        }
        resolve(response)
      }).catch((e) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_NOT_FOUND,
          payload: {
            plan: {}
          }
        })
        reject(e)
      })
    })
  }
}

export function getSubscriptionAbilitiesAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/billing/subscription/abilities').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_ABILITIES_GET,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getSubscriptionPaymentAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/billing/subscription/payment').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_PAYMENT_GET,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getSubscriptionInvoicesAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/billing/subscription/invoices').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_INVOICES_GET,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function deleteSubscriptionAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.DELETEv2('v1/billing/subscription').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.SUBSCRIPTION_DELETE,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}
