import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import CFTheme from '../../../constants/cf-theme.less'
import {
  Button,
  Divider,
  Grid,
  Header,
  Segment,
  Modal,
  Confirm
} from 'semantic-ui-react'
import { createSessionAPI, deleteSubscriptionAPI, getSubscriptionAPI } from '../../../actions/billing-actions'
import { getFormsAPI, getStatsAPI } from '../../../actions/form-actions'

export default function PlanSelector (props) {
  const { plan } = useSelector((state) => state.subscription)
  const dispatch = useDispatch()
  const [downgradeButtonDisabled, setDowngradeButtonDisabled] = useState(false)
  const [downgradeButtonBusy, setDowngradeButtonBusy] = useState(false)
  const [resubscribeButtonDisabled, setResubscribeButtonDisabled] = useState(false)
  const [resubscribeButtonBusy, setResubscribeButtonBusy] = useState(false)
  const [downgradeConfirmOpen, setDowngradeConfirmOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const { nextPayment, now, name: planName } = plan
  const isActive = now < nextPayment

  useEffect(() => {
    if (plan.planLoaded && !isActive && plan.enabled) {
      setOpen(true)
    }
  }, [plan.planLoaded, isActive])

  const handleResubscribe = () => {
    setResubscribeButtonBusy(true)
    setDowngradeButtonDisabled(true)
    dispatch(createSessionAPI()).then((session) => {
      window.location = session.url
    })
  }

  const handleDowngrade = () => {
    setDowngradeConfirmOpen(false)
    setDowngradeButtonBusy(true)
    setResubscribeButtonDisabled(true)
    dispatch(deleteSubscriptionAPI()).then(() => {
      dispatch(getFormsAPI())
      dispatch(getStatsAPI())
      dispatch(getSubscriptionAPI())
      setOpen(false)
    })
  }

  const nextPaymentDate = new Date(nextPayment * 1000)

  return (<>
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      data-cy='cf-inactive-plan-modal'
      closeOnDimmerClick={false}
      closeOnEscape={false}
    >
      <Modal.Header>Subscription inactive</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>Unfortunately your <b>{planName}</b> plan subscription became inactive on <b>{nextPaymentDate.toLocaleDateString()}</b>.</p>
          <p style={{ padding: '1em 0' }}>This may have been due to a number of reasons such as an issue with the payment method associated or you chose to cancel the subscription.</p>
          <p style={{ padding: '1em 0' }}>To continue using ContactForm.Pro, please choose an option below.</p>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign='center'>
              <Divider vertical>Or</Divider>
              <Grid.Row >
                <Grid.Column>
                  <Header >
                    Resubscribe
                  </Header>
                  <p style={{ padding: '1em 0' }}>
                    Resubscribing to the <b>{planName}</b> plan will allow you to retain your current setup with any customisation you may have configured.
                  </p>
                  <Button disabled={resubscribeButtonDisabled} loading={resubscribeButtonBusy} color='green' className={CFTheme.cfButtonCyan} onClick={() => handleResubscribe()}>
                    Resubscribe
                  </Button>
                </Grid.Column>

                <Grid.Column>
                  <Header icon>
                    Downgrade
                  </Header>
                  <p style={{ padding: '1em 0' }}>
                    Alternatively downgrade to the <b>Free</b> plan without any further cost, but with fewer features and limits. <a href="https://contactform.pro/pricing/" target="_blank" rel="noreferrer">See plans</a>
                  </p>
                  <Button disabled={downgradeButtonDisabled} loading={downgradeButtonBusy} basic onClick={() => setDowngradeConfirmOpen(true)}>
                    Downgrade
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={downgradeButtonDisabled} loading={downgradeButtonBusy} basic onClick={() => setDowngradeConfirmOpen(true)}>
          Downgrade
        </Button>
        <Button disabled={resubscribeButtonDisabled} loading={resubscribeButtonBusy} color='green' className={CFTheme.cfButtonCyan} onClick={() => handleResubscribe()}>
          Resubscribe
        </Button>
      </Modal.Actions>
      <Confirm
        open={downgradeConfirmOpen}
        onCancel={() => setDowngradeConfirmOpen(false)}
        onConfirm={() => handleDowngrade()}
        size="tiny"
        header="Confirm downgrade"
        content="Downgrading to the free plan will reset/remove any features and limits that are only available in paid plans."
      />
    </Modal>
  </>)
}

PlanSelector.propTypes = {
  onClose: PropTypes.func,
  newUser: PropTypes.bool,
  stripeId: PropTypes.string
}
