import { Button, Input, Modal } from 'semantic-ui-react'
import { createFormAPI } from '../../../actions/form-actions'
import { getEmailVerificationAPI, confirmEmailVerificationAPI } from '../../../actions/account-actions'

import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withRouter from '../../../utilities/withRouter'
import validator from 'validator'
import CFTheme from '../../../constants/cf-theme.less'

class EmailVerify extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: true,
      email: '',
      code: '',
      busy: false,
      error: false,
      isCodeFormatValid: false,
      requestedCode: false,
      dispatchedCode: false
    }
    this.handleCodeUpdate = this.handleCodeUpdate.bind(this)
    this.handleRequestCode = this.handleRequestCode.bind(this)
  }

  setOpen (open, cancel = false) {
    const { onClose } = this.props
    if (!open) {
      onClose(false)
    }
    this.setState({
      open
    })
  }

  handleCodeUpdate (event) {
    this.setState({
      error: false
    })

    let code = event.target.value
    code = code.trim()
    code = code.substring(0, 6)
    this.setState({
      code
    })

    const condition = (validator.isLength(code, { min: 6, max: 6 }) && validator.isNumeric(code))

    if (condition) {
      this.setState({
        isCodeFormatValid: true
      })
    } else {
      this.setState({
        isCodeFormatValid: false
      })
    }
  }

  handleRequestCode (event) {
    this.setState({
      requestedCode: true
    })
    const { email } = this.props
    getEmailVerificationAPI(email).then(() => {
      this.setState({
        dispatchedCode: true
      })
    })
  }

  handleVerify (event) {
    const { code } = this.state
    const { email, onClose } = this.props
    this.setState({
      busy: true
    })
    confirmEmailVerificationAPI(email, code).then(() => {
      onClose(true)
    }).catch(() => {
      this.setState({ error: true, busy: false })
    })
  }

  render () {
    const { busy, error, isCodeFormatValid, code, dispatchedCode, requestedCode } = this.state
    const { email } = this.props
    return (<>
      <Modal
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
        open={this.state.open}
        size='tiny'
        data-cy='cf-verify-modal'
      >
        <Modal.Header>Verify email</Modal.Header>
        <Modal.Content>
          {dispatchedCode
            ? <Modal.Description>
              Enter the verification code below that we just sent to {email}
              <div style={{ padding: '1em 0' }}>
                <Input label="Code" onChange={this.handleCodeUpdate} value={code} error={error} />
              </div>
              {/* <a>Need another code?</a> */}
            </Modal.Description>
            : <Modal.Description>
              We just need to send a verification code to confirm the new email.
              <div style={{ padding: '1em 0' }}>
                <Button className={CFTheme.cfButtonCyan} onClick={this.handleRequestCode} loading={requestedCode} disabled={requestedCode}>Verify email</Button>
              </div>
            </Modal.Description>}
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => this.setOpen(false, true)}>
            Cancel
          </Button>
          <Button
            content="Verify"
            labelPosition='right'
            icon='checkmark'
            onClick={() => this.handleVerify()}
            positive
            loading={busy}
            disabled={!isCodeFormatValid || busy || error}
          />
        </Modal.Actions>
      </Modal>
    </>)
  }
}

EmailVerify.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func
}

const mapStateToProps = ({ subscription, form }) => {
  const { plan } = subscription
  const { forms } = form
  return ({
    max: plan.forms,
    count: forms.length
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createFormAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(EmailVerify))
