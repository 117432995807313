import { Button, Confirm, Grid, Menu, Icon, Label } from 'semantic-ui-react'
import { deleteFormAPI, getFormAPI, getFormsAPI } from '../../../actions/form-actions'

import AppearanceCards from './appearance'
import ConfigCards from './configuration'
import InstallationCards from './installation'
import Preview from './preview'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { capitalize } from 'lodash-es'
import { connect } from 'react-redux'
import styles from './styles.less'
import InstallModal from './install-modal'
import FieldsModal from '../../dialogs/field-editor/fields-modal'
import EnticeModal from '../../dialogs/entice-editor/entice-modal'
import FeedbackModal from '../../dialogs/feedback'
import withRouter from '../../../utilities/withRouter'
import Tour from './tour'

class FormEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentMenu: 'appearance',
      deleteConfirmOpen: false,
      deleteBusy: false,
      installModalVisible: this.props.initial,
      fieldEditorOpen: false,
      enticeEditorOpen: false,
      feedbackOpen: false
    }
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this)
    this.deleteForm = this.deleteForm.bind(this)
    this.openFieldModal = this.openFieldModal.bind(this)
    this.openEnticeModal = this.openEnticeModal.bind(this)
    this.openFeedback = this.openFeedback.bind(this)
  }

  componentDidMount () {
    const { getFormAPI, id } = this.props
    getFormAPI(id)
  }

  handleMenuChange (event, menu) {
    event.preventDefault()
    this.setState({
      currentMenu: menu
    })
  }

  openDeleteConfirm (event) {
    const { id } = this.props
    event.preventDefault()
    this.setState((prevState) => ({
      deleteConfirmOpen: !prevState.deleteConfirmOpen,
      formDeletionId: id
    }))
  }

  deleteForm () {
    const { deleteFormAPI, getFormsAPI, router } = this.props
    const { formDeletionId } = this.state

    this.setState({
      deleteBusy: true
    })

    deleteFormAPI(formDeletionId).then(() => {
      getFormsAPI().then(() => {
        this.setState({
          deleteBusy: false,
          deleteConfirmOpen: false
        }, () => {
          router.navigate('/')
        })
      })
    })
  }

  handleInstallModalState (state) {
    this.setState({
      installModalVisible: state
    })
  }

  openFieldModal (state) {
    this.setState({
      fieldEditorOpen: state
    })
  }

  openEnticeModal (state) {
    this.setState({
      enticeEditorOpen: state
    })
  }

  openFeedback (state) {
    this.setState({
      feedbackOpen: state
    })
  }

  render () {
    const { currentMenu, deleteConfirmOpen, deleteBusy } = this.state
    const { currentForm, busy } = this.props
    const { openDeleteConfirm, deleteForm } = this
    return <>
      <Grid columns={3} divided style={{ maxHeight: 'calc(100vh - 12em)' }}>
        <Grid.Row style={{ maxHeight: 'calc(100vh - 12em)' }}>
          <Grid.Column width={3} style={{ height: '100%', overflowY: 'auto' }}>
            <Menu pointing vertical fluid size='huge' className={styles.menu}>
              <Menu.Item header
                name='Preferences'
              />
              <Menu.Item
                name='Appearance'
                onClick={(event) => this.handleMenuChange(event, 'appearance')}
                active={currentMenu === 'appearance'}
              />
              <Menu.Item
                name='Configuration'
                onClick={(event) => this.handleMenuChange(event, 'configuration')}
                active={currentMenu === 'configuration'}
              />
              <Menu.Item
                onClick={(event) => this.openFieldModal(true)}
              >Layout <span style={{ fontWeight: 'bold', color: '#b7b7b7', fontSize: 'smaller' }}>Beta</span></Menu.Item>
              <Menu.Item
                onClick={(event) => this.openEnticeModal(true)}
              >Entice <span style={{ fontWeight: 'bold', color: '#b7b7b7', fontSize: 'smaller' }}>Beta</span></Menu.Item>
              <Menu.Item
                onClick={(event) => this.handleInstallModalState(true)}
              >Install {!this.props.currentForm.verified && <Label basic color='red'>Unverified</Label>}</Menu.Item>
              <Menu.Item
                name='Delete'
                onClick={(event) => this.openDeleteConfirm(event)}
              />
            </Menu>
            <div style={{ margin: '1em', cursor: 'pointer' }}>
              <a onClick={(event) => this.openFeedback(event)}>Feedback or request?</a>
            </div>
          </Grid.Column>
          <Grid.Column width={6} style={{ height: '100%', overflowY: 'auto' }}>
            <Menu pointing vertical fluid size='huge' className={styles.menu}>
              <Menu.Item header
                name={capitalize(currentMenu)}
                onClick={this.handleItemClick}
              />
            </Menu>
            {(() => {
              switch (currentMenu) {
                case 'appearance':
                  return <AppearanceCards {...currentForm} />
                case 'installation':
                  return <InstallationCards {...currentForm} />
                case 'configuration':
                default:
                  return <ConfigCards {...currentForm} />
              }
            })()}
          </Grid.Column>
          <Grid.Column width={7} style={{ height: '100%', overflowY: 'auto' }}>
            <Menu pointing vertical fluid size='huge' className={styles.menu}>
              <Menu.Item
                header
                onClick={this.handleItemClick}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >Preview<SaveState busy={busy} /></Menu.Item>
            </Menu>
            <Preview {...currentForm} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Confirm
        open={deleteConfirmOpen}
        confirmButton={<Button loading={deleteBusy} disabled={deleteBusy}>Yes</Button>}
        header='Delete form'
        onCancel={(event) => openDeleteConfirm(event)}
        onConfirm={() => deleteForm()}
        size='tiny'
      />

      {this.state.feedbackOpen && <FeedbackModal open={this.state.feedbackOpen} setOpen={this.openFeedback} />}
      {this.state.installModalVisible && <InstallModal open={this.state.installModalVisible} id={this.props.id} handleInstallModalState={(state) => this.handleInstallModalState(state)} />}
      {this.state.fieldEditorOpen && <FieldsModal open={this.state.fieldEditorOpen} handleOpen={this.openFieldModal} />}
      {this.state.enticeEditorOpen && <EnticeModal open={this.state.enticeEditorOpen} handleOpen={this.openEnticeModal} />}
      {!this.state.installModalVisible && <Tour />}
    </>
  }
}

FormEdit.propTypes = {
  getFormsAPI: PropTypes.func,
  deleteFormAPI: PropTypes.func,
  forms: PropTypes.array,
  id: PropTypes.string,
  router: PropTypes.object,
  getFormAPI: PropTypes.func,
  currentForm: PropTypes.object,
  initial: PropTypes.bool,
  busy: PropTypes.bool
}

const mapStateToProps = ({ form: { forms }, form: { currentForm, busy } }, ownProps) => {
  const { id } = ownProps.router.params
  const form = forms.find(form => form.id === id)
  return ({ form, id, currentForm, busy })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getFormsAPI, deleteFormAPI, getFormAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(FormEdit))

const SaveState = function (props) {
  const { busy } = props
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    if (busy) {
      setHidden(false)
    }
    const timeout = setTimeout(() => setHidden(true), 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [busy])

  return (!hidden && <div style={{ fontWeight: '100', fontSize: 'smaller', color: '#767676' }}>
    {busy ? 'Saving...' : <span>Saved <Icon name='thumbs up outline' /></span>}
  </div>)
}

SaveState.propTypes = {
  busy: PropTypes.bool
}
