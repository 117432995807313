.cfButtonCyan {
    background-color: #038a86 !important;
    color: white !important;

    &:hover {
        background-color: #04bfb9 !important;
    }

    &:active {
        background-color: #038a86 !important;
    }
}

.cfButtonCyanNoHover {
    background-color: #038a86 !important;
    color: white !important;
}

.cfButtonYellow {
    background-color: #FBBC0A !important;
    color: white !important;

    &:hover {
        background-color: #eeb109 !important;
    }
}

.cfTooltipCyan {
    background: white !important;
    color: #038a86 !important;
    border: solid 1px #038a86;
    border-radius: 0.5em !important;
    z-index: 999;
}

.cfTooltipCyanArrow {
    display: none;
}