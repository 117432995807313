import React from 'react'
import { Tooltip } from 'react-tooltip'
import CFTheme from '../../constants/cf-theme.less'

const UpgradeTooltip = () => (
    <div>
        <Tooltip
            anchorSelect=".disabledCardTooltip"
            style={{ opacity: 1 }}
            clickable
            className={CFTheme.cfTooltipCyan}
            classNameArrow={CFTheme.cfTooltipCyanArrow}
            content={
                <div>Upgrade your plan to use this feature <a href="/billing" className={'ui button compact tiny basic yellow'} style={{ marginLeft: '1em' }}>Upgrade</a></div>} />
    </div>
)

export default UpgradeTooltip
