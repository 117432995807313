import React from 'react'
import { Modal, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export default function FeedbackModal (props) {
  const { user } = useSelector(state => state.account)
  return <Modal
    open={props.open}
    onClose={() => props.setOpen(false)}
    size='small'
  >
    <Modal.Content>
      <iframe src={`https://docs.google.com/forms/d/e/1FAIpQLSeWn0O05_ufgjduo9rN3DmKkOPlIFb1DN-5J4AqpUsZ5BLWGA/viewform?embedded=true&entry.965855279=${user.email}`} width="640" height="573" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => props.setOpen(false)}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
}

FeedbackModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func
}
