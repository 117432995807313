import React from 'react'
import { Container, Image, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import styles from './styles.less'

const Footer = () => (
  <Menu fixed='bottom' borderless className={styles.footer} inverted>
    <Container>
      <Menu.Item as='a' href="https://contactform.pro" target="_blank">
        <Image size='mini' src='https://assets.contactform.pro/images/CF_WHITE_50.png' />
      </Menu.Item>
      <Menu.Menu fixed='top' position='right'>
        <Menu.Item as={Link} to='/billing' className={styles.item}>Billing</Menu.Item>
        <Menu.Item href='https://contactform.pro/help/' target='_blank'>Help</Menu.Item>
        <Menu.Item as='a' href="https://contactform.pro/faq" target="_blank">FAQ</Menu.Item>
        <Menu.Item>&copy; Cloud Wrestlers Ltd 2024</Menu.Item>
      </Menu.Menu>
    </Container>
  </Menu>
)

export default Footer
