.widgetWireframe {
    box-shadow: 0 0 10px #0003;
    margin: 0 5em;
    padding: 2em;
    border-radius: 1em;
}

.fieldItem { 
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.disabledFieldDialogOverlay {
    z-index: 9;
    display: flex;
    background: #cecece96;
    position: absolute;
    inset: 0;
    justify-content: center;
    align-items: center;
}

.disabledFieldDialogLock {
    position: absolute;
    right: 1em;
    top: 1em;
    font-size: 30px;
}