/* eslint-disable camelcase */
import React from 'react'
import { Grid, Segment, Header, Button, Table, Icon, Label } from 'semantic-ui-react'
import styles from './styles.less'
import { capitalize } from 'lodash-es'

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createSessionAPI, getSubscriptionAPI, deleteSubscriptionAPI, getSubscriptionPaymentAPI, getSubscriptionInvoicesAPI } from '../../actions/billing-actions'

const isDev = process.env.IS_DEV

function toDateTime (secs) {
  const t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return t.getDate() +
    '/' + (t.getMonth() + 1) +
    '/' + t.getFullYear()
}

function toDateMonthYear (secs) {
  const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const t = new Date(1970, 0, 1) // Epoch
  t.setSeconds(secs)
  return `${t.getDate()} ${month[t.getMonth()]} ${t.getFullYear()}`
}
class Billing extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      active: false,
      deleteBusy: false,
      sessionBusy: false
    }
  }

  componentDidMount () {
    // TODO this is already called in App.js
    this.props.getSubscriptionAPI().then(() => {
      const { plan } = this.props.subscription
      const { nextPayment, now } = plan
      const isActive = now < nextPayment
      if (plan.name && plan.name !== 'internal_plan' && isActive) {
        this.props.getSubscriptionPaymentAPI()
        this.props.getSubscriptionInvoicesAPI()
      }
      this.setState({
        active: true
      })
    }).catch(() => {
      this.setState({
        active: false
      })
    })
  }

  handleSessionCreation (event) {
    this.setState({
      sessionBusy: true
    })
    this.props.createSessionAPI().then(session => {
      window.location = session.url
    }).catch(() => {
      this.setState({
        sessionBusy: false
      })
    })
  }

  deleteSubscription (event) {
    this.setState({
      deleteBusy: true
    })

    this.props.deleteSubscriptionAPI().then(() => {
      this.props.getSubscriptionAPI().then(() => {
        this.setState({
          active: true,
          deleteBusy: false
        })
      }).catch(() => {
        this.setState({
          active: false,
          deleteBusy: false
        })
      })
    })
  }

  render () {
    const { plan, payment, invoices } = this.props.subscription
    const { name, nextPayment, cancelling, price, now } = plan
    const isActive = now < nextPayment
    const pricing = isDev
      ? <stripe-pricing-table pricing-table-id="prctbl_1LksmSCRTL5sPHZXK5Jldu9g" publishable-key="pk_test_51LYDq9CRTL5sPHZXcoO1cL8arTBhVUW3xWtqU1KN2kcddkIZgwatuTX76az2zu3ngjWGz1ZjlQiOfHC6dI2soHix002dvLbIUm" client-reference-id={this.props.stripeId}></stripe-pricing-table>
      : <stripe-pricing-table pricing-table-id="prctbl_1MPPofCRTL5sPHZX9gTi3Ic6" publishable-key="pk_live_51LYDq9CRTL5sPHZXHRYg1AfffHuRS8dDuysFiXDt3brbxAvYMq9MTvrwKXsC3giwD4CWIMN0UaWB2VZfhds6DpcF00PQF50Zpm" client-reference-id={this.props.stripeId}></stripe-pricing-table>

    if (!this.state.active) {
      return this.props.stripeId ? pricing : 'Error loading plans'
    }

    return <div>
      <Grid columns={3} centered>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment raised loading={!plan.planLoaded}>
              <Header as='h2'>Plan {isActive
                ? cancelling
                  ? <Label pointing='left' basic color='yellow'>Cancelling</Label>
                  : <Label pointing='left' basic color='green'>Active</Label>
                : <Label pointing='left' basic color='red'>Expired</Label>}
                <Button primary compact basic floated='right' disabled={this.state.sessionBusy} loading={this.state.sessionBusy} onClick={() => this.handleSessionCreation()}>{isActive ? 'Update' : 'Upgrade'}</Button>
              </Header>

              <Table basic='very'>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Billing term</Table.Cell>
                    <Table.Cell textAlign='right' className={styles.subtleGrey}>Month</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{capitalize(name)}</Table.Cell>
                    <Table.Cell textAlign='right' className={styles.subtleGrey}>£{price}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell>Total</Table.Cell>
                    <Table.Cell textAlign='right' className={styles.totalLarge}>£{price}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {isActive && <Grid columns={3} centered>
        <Grid.Row>
          <Grid.Column width={5}>
            <Segment secondary loading={plan.name !== 'internal_plan' ? !payment.paymentLoaded : false}>
              <Header as='h4'>Payment
                <Button size="mini" basic floated='right' disabled={this.state.sessionBusy} loading={this.state.sessionBusy} onClick={() => this.handleSessionCreation()}>Edit</Button>
              </Header>

              <Grid verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column width={2}>
                    <div className={styles.cardVendor}>
                      <Icon name='credit card outline' />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <div className={styles.cardDetails}>
                      {capitalize(payment.type)} ending in {payment.last4}<br />Expires {payment.expiry}
                    </div>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column>
                    Billing period {isActive ? cancelling ? 'ends' : 'renews' : 'ended'} <b>{toDateTime(nextPayment)}</b>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column width={5}>
            <Segment secondary loading={plan.name !== 'internal_plan' ? !invoices.invoicesLoaded : false}>
              <Header as='h4'>Invoices
                <Button size="mini" basic floated='right' disabled={this.state.sessionBusy} loading={this.state.sessionBusy} onClick={() => this.handleSessionCreation()}>View all</Button>
              </Header>
              <Table basic='very'>
                <Table.Body>
                  {invoices.invoices.map((invoice, key) => {
                    return <Table.Row key={key}>
                      <Table.Cell><a href={invoice.url}>{toDateMonthYear(invoice.created)}</a></Table.Cell>
                      <Table.Cell textAlign='right'>{invoice.total}</Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>}

    </div>
  }
}

Billing.propTypes = {
  createSessionAPI: PropTypes.func,
  stripeId: PropTypes.string,
  getSubscriptionAPI: PropTypes.func,
  subscription: PropTypes.object,
  deleteSubscriptionAPI: PropTypes.func,
  getSubscriptionPaymentAPI: PropTypes.func,
  getSubscriptionInvoicesAPI: PropTypes.func
}

const mapStateToProps = ({ account, subscription }) => {
  const { user } = account
  return ({ stripeId: user.sub, subscription })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createSessionAPI, getSubscriptionAPI, deleteSubscriptionAPI, getSubscriptionPaymentAPI, getSubscriptionInvoicesAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Billing)
