import { Card, Message, Dropdown, Icon, Checkbox } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { putFormConfigAPI } from '../../../../actions/form-actions'
import { getEmailsAPI } from '../../../../actions/account-actions'
import { useDispatch, useSelector } from 'react-redux'
import UpgradeTooltip from '../../../common/UpgradeTooltip'

function ConfigurationCards (props) {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState(false)
  const [enableError, setEnableError] = useState(false)
  const [targetEmailBusy, setTargetEmailBusy] = useState(false)
  const [enticeBusy, setEnticeBusy] = useState(false)
  const { emails } = useSelector(state => state.account)
  const { abilities } = useSelector((state) => state.subscription)

  useEffect(() => {
    dispatch(getEmailsAPI())
  }, [getEmailsAPI])

  const verifiedEmailOptions = emails.map((email) => {
    return {
      key: email.id,
      text: email.email,
      value: email.id
    }
  })

  const handleUpdate = function () {
    setBusy(true)
    setEnableError(false)
    dispatch(putFormConfigAPI(props.id, { enabled: !props.enabled })).then(() => {
      setBusy(false)
    }).catch(() => {
      setEnableError(true)
      setBusy(false)
    })
  }

  const handleTargetUpdate = function (_event, data) {
    setTargetEmailBusy(true)
    dispatch(putFormConfigAPI(props.id, { targetEmail: data.value })).then(() => {
      setTargetEmailBusy(false)
    }).catch(() => {
      setTargetEmailBusy(false)
    })
  }

  const handleEnticeUpdate = function () {
    setEnticeBusy(true)
    dispatch(putFormConfigAPI(props.id, { entice: !props.entice })).then(() => {
      setEnticeBusy(false)
    }).catch(() => {
      setEnticeBusy(false)
    })
  }

  const disabledCard = { background: 'rgba(0,0,0,0.05)' }
  const disabledLock = <div style={{ float: 'right' }}><Icon name='lock' /></div>

  const { abilities: planAbilities } = abilities
  const targetEmailEnabled = planAbilities.includes('updateTargetEmail')
  return (
    <Card.Group>
      <Card fluid>
        <Card.Content>
          <Card.Header>Entice</Card.Header>
          <Card.Description>
            Enable pop up messaging to capture users attention.
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Checkbox toggle label='Visible' checked={props.entice} disabled={enticeBusy} onClick={handleEnticeUpdate} />
        </Card.Content>
      </Card>
      <Card fluid style={!targetEmailEnabled ? { ...disabledCard } : {}} className={!targetEmailEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Target Email{!targetEmailEnabled && disabledLock}</Card.Header>
          <Card.Description>
            Choose which email alerts for this form should be sent.
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Dropdown selection fluid disabled={targetEmailBusy | !targetEmailEnabled} onChange={handleTargetUpdate} options={verifiedEmailOptions} value={props.targetEmail} placeholder='Choose an option' />
        </Card.Content>
        <Card.Content>
          <Card.Description>
            Manage email addresses from your <a href="/account">account</a>.
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Enable</Card.Header>
          <Card.Description>
            Allow this form to load on your website.
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <Checkbox toggle label='Enable' checked={props.enabled} disabled={busy} onClick={handleUpdate} />
          {enableError
            ? <Message negative size='tiny'>
              <p>Cannot exceed plan form count</p>
            </Message>
            : null}
        </Card.Content>
      </Card>
      {abilities.abilitiesLoaded ? <UpgradeTooltip /> : null}
    </Card.Group>
  )
}

ConfigurationCards.propTypes = {
  enabled: PropTypes.bool,
  id: PropTypes.string,
  targetEmail: PropTypes.string,
  entice: PropTypes.bool
}

export default ConfigurationCards
