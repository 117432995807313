import { Button, Container, Divider, Header } from 'semantic-ui-react'
import { Link, Route, Routes } from 'react-router-dom'
import { getCurrentUser, signIn, signOut } from './actions/account-actions'
import { getStatsAPI } from './actions/form-actions'
import withRouter from './utilities/withRouter'

import AuthBlanket from './components/blanket'
import CFEdit from './components/forms/editor'
import CFBilling from './components/billing'
import CFAccount from './components/account'
import CFFooter from './components/footer'
import CFHeader from './components/header'
import CFList from './components/forms/cards'
import CreateForm from './components/dialogs/create'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PlanSelector from './components/dialogs/plan-selector'
import InactivePlan from './components/dialogs/inactive-plan'

import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group'

import { getSubscriptionAPI, getSubscriptionAbilitiesAPI } from './actions/billing-actions'
import './App.less'

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      signedIn: false,
      subscriptionActive: false
    }
  }

  componentDidMount () {
    const { getCurrentUser, getSubscriptionAPI, getSubscriptionAbilitiesAPI, signIn, getStatsAPI } = this.props
    getCurrentUser().then(() => {
      this.setState({
        signedIn: true

      })

      getSubscriptionAPI().then(() => {
        this.setState({
          subscriptionActive: true
        })
      }).catch((e) => {
        console.log('catch', e)
        this.setState({
          subscriptionActive: false
        })
        getSubscriptionAbilitiesAPI()
      })

      getStatsAPI()
    }).catch(() => {
      signIn()
    })
  }

  render () {
    if (!this.state.signedIn) return <AuthBlanket enabled={true} />
    const location = this.props.router.location
    return <TransitionGroup>
      <AuthBlanket enabled={!this.state.signedIn || !this.props.accountLoaded} />
      <CFHeader />
      <CSSTransition
        key={location.pathname} timeout={300} classNames="fade">
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Container style={{ marginTop: '7em', paddingBottom: '7em', position: 'relative' }}>
            <Routes location={location}>
              <Route exact path='/forms/edit/:id/initial' element={
                <>
                  <CFEdit {...this.props} initial />
                </>
              } />
              <Route exact path='/forms/edit/:id' element={
                <>
                  <CFEdit {...this.props} />
                </>
              } />

              <Route path='/' element={
                <>
                  <Header as='h1' size='large'>Forms <div style={{ float: 'right', display: this.props.newUser ? 'none' : 'block' }}><CreateForm /></div></Header>
                  <Divider hidden />
                  <InactivePlan />
                  <PlanSelector />
                  <CFList />
                </>
              } />
              <Route path='/account' element={
                <>
                  <Header as='h1' size='large'>Account
                    <Button primary floated='right' as={Link} to={'/'}>Back</Button>
                  </Header><br />
                  <CFAccount />
                  <Divider hidden />
                </>
              } />
              <Route path='/billing' element={
                <>
                  <Header as='h1' size='large'>Billing
                    <Button primary floated='right' as={Link} to={'/'}>Back</Button>
                  </Header><br />
                  <CFBilling />
                  <Divider hidden />
                </>
              } />
              <Route path='/billing/returning' element={
                <>
                  <Header as='h1' size='large'>Billing
                    <Button primary floated='right' as={Link} to={'/'}>Back</Button>
                  </Header><br />
                  <CFBilling />
                  <Divider hidden />
                </>
              } />
            </Routes>
          </Container>
        </div>
      </CSSTransition>
      <CFFooter />
    </TransitionGroup>
  }
}

App.propTypes = {
  createAuthenticationListener: PropTypes.func,
  getCurrentUser: PropTypes.func,
  signIn: PropTypes.func,
  getSubscriptionAPI: PropTypes.func,
  getSubscriptionAbilitiesAPI: PropTypes.func,
  formsDisabled: PropTypes.bool,
  router: PropTypes.object,
  newUser: PropTypes.bool,
  accountLoaded: PropTypes.bool,
  getStatsAPI: PropTypes.func
}

const mapStateToProps = ({ form, account: { loggedIn } }) => {
  const { allDisabled } = form
  return ({ formsDisabled: allDisabled, newUser: form.forms.length < 1, accountLoaded: loggedIn })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getCurrentUser, signIn, signOut, getSubscriptionAPI, getSubscriptionAbilitiesAPI, getStatsAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(App))
