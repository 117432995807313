import React, { useState, useEffect } from 'react'
import { Grid, Segment, Header, Table, Input, Button, Modal, Icon } from 'semantic-ui-react'
import EmailVerify from '../dialogs/email-verify'
import validator from 'validator'
import { getEmailsAPI, deleteEmailAPI } from '../../actions/account-actions'
import { useSelector, useDispatch } from 'react-redux'
import UpgradeTooltip from '../common/UpgradeTooltip'

export default function Account (props) {
  const [verifyOpen, setVerifyOpen] = useState(false)
  const [targetEmail, setTargetEmail] = useState('')
  const [targetEmailValid, setTargetEmailValid] = useState(false)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [deleteBusy, setDeleteBusy] = useState(false)
  const [emailToBeDeleted, setEmailToBeDeleted] = useState({})
  const dispatch = useDispatch()
  const { emails } = useSelector((state) => state.account)
  const { abilities } = useSelector((state) => state.subscription)

  useEffect(() => {
    dispatch(getEmailsAPI())
  }, [getEmailsAPI])

  const handleAddEmail = () => {
    setVerifyOpen(true)
  }

  const handleEmailChange = (event) => {
    const email = event.target.value
    const valid = validator.isEmail(email)
    setTargetEmailValid(valid)
    setTargetEmail(email)
  }

  const onVerifyClose = (success) => {
    setVerifyOpen(false)

    if (success) {
      dispatch(getEmailsAPI())
    }
  }

  const handleDeleteConfirm = (email) => {
    setEmailToBeDeleted(email)
    setDeleteConfirmOpen(true)
  }

  const handleEmailDelete = (id) => {
    setDeleteBusy(true)
    deleteEmailAPI(id).then(() => {
      setDeleteConfirmOpen(false)
      dispatch(getEmailsAPI())
      setDeleteBusy(false)
      setEmailToBeDeleted({})
    })
  }

  const disabledCard = { background: 'rgba(0,0,0,0.05)' }
  const disabledLock = <div style={{ float: 'right' }}><Icon name='lock' /></div>

  const { abilities: planAbilities } = abilities
  const targetEmailEnabled = planAbilities.includes('updateTargetEmail')

  return (
    <div>
      <Grid columns={3} centered>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment raised style={!targetEmailEnabled ? { ...disabledCard } : {}} className={!targetEmailEnabled ? 'disabledCardTooltip' : ''}>
              <Header as='h2'>Target emails{!targetEmailEnabled && disabledLock}</Header>

              <Table basic='very'>

                <Table.Body>
                  {emails.map((email, index) => {
                    return <Table.Row key={index}>
                      <Table.Cell>{email.email}</Table.Cell>
                      <Table.Cell textAlign='right'>
                        {email.id !== 'parent'
                          ? <Button compact basic onClick={() => handleDeleteConfirm(email)}>Delete</Button>
                          : <Button compact basic disabled>Delete</Button>}
                      </Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell colSpan='2'>
                      <Input
                        fluid
                        disabled={!targetEmailEnabled}
                        action={{
                          labelPosition: 'left',
                          icon: 'at',
                          content: 'Add email',
                          basic: true,
                          onClick: () => handleAddEmail(),
                          disabled: !targetEmailValid
                        }}
                        placeholder="email@mail.tld"
                        onChange={(event) => handleEmailChange(event)}
                      />
                      {verifyOpen ? <EmailVerify email={targetEmail} onClose={onVerifyClose} /> : null}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>

            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Modal
          onClose={() => setDeleteConfirmOpen(false)}
          onOpen={() => setDeleteConfirmOpen(true)}
          open={deleteConfirmOpen}
          size='tiny'
          data-cy='cf-email-delete-modal'
        >
          <Modal.Header>Delete email?</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              Are you sure you want to delete {emailToBeDeleted.email}?
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => setDeleteConfirmOpen(false)}>
              Cancel
            </Button>
            <Button
              content="Delete"
              onClick={() => handleEmailDelete(emailToBeDeleted.id)}
              negative
              loading={deleteBusy}
              disabled={deleteBusy}
            />
          </Modal.Actions>
        </Modal>
      </Grid>
      {abilities.abilitiesLoaded ? <UpgradeTooltip /> : null}
    </div>
  )
}
