.menu {
    box-shadow: none !important;
    // border: none !important;
}

.previewDisabled {
    font-size: 18px;
    text-align: center;
    padding: 20px;
}

.previewDisabled p {
    padding: 20px;
}