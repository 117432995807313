import React, { useEffect, useState } from 'react'
import { Button, Header, Icon, Modal, Segment, Label, Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getAvailableFieldsAPI, getFieldsLayoutAPI, updateFields } from '../../../actions/form-actions'
import _ from 'lodash-es'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  arrayMove
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styles from './styles.less'
import UpgradeTooltip from '../../common/UpgradeTooltip'

import NewFieldModal from '../new-field'

export default function FieldsModal (props) {
  const [open, setOpen] = React.useState(false)
  const [openNewFieldModal, setOpenNewFieldModal] = React.useState(false)
  const dispatch = useDispatch()
  const { availableFields, currentForm } = useSelector((state) => state.form)
  const [fields, setFields] = useState([])
  const [compareFields, setCompareFields] = useState([])
  const [busy, setBusy] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const { abilities } = useSelector((state) => state.subscription)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    if (props.open) {
      setOpen(true)
    }
    const { id: formId } = currentForm
    dispatch(getAvailableFieldsAPI())
    dispatch(getFieldsLayoutAPI(formId)).then(() => {
      setFields([...currentForm.fields])
      setCompareFields([...currentForm.fields])
    })
  }, [props.open])

  useEffect(() => {
    setIsChanged(!_.isEqual(fields, compareFields))
  }, [JSON.stringify(fields), JSON.stringify(compareFields)])

  const _open = (state) => {
    setOpen(state)
    props.handleOpen(state)
  }

  function handleDragEnd (event) {
    const { active, over } = event
    if (active && over) {
      if (active.id !== over.id) {
        setFields((items) => {
          return arrayMove(items, active.id - 1, over.id - 1)
        })
      }
    }
  }

  function handleFieldsSave (_event) {
    setBusy(true)
    const { id: formId } = currentForm
    const reduced = fields.map(field => {
      return {
        label: field.label,
        parent: field.parent
      }
    })

    if (isChanged) {
      dispatch(updateFields(formId, reduced)).then(() => {
        _open(false)
      }).catch((e) => {
        setBusy(false)
      })
    } else {
      _open(false)
    }
  }

  function handleAddNewField (event) {
    setOpenNewFieldModal(true)
  }

  function handleFieldsUpdate (newField) {
    setFields((fields) => {
      newField.id = fields.length + 1
      fields.push(newField)
      return fields
    })
    setOpenNewFieldModal(false)
  }

  function handleFieldDelete (id) {
    setFields(fields.filter((field) => field.id !== id))
  }

  const { abilities: planAbilities } = abilities
  const fieldEditorEnabled = planAbilities.includes('updateFieldLayout')

  return (
    <Modal
      onClose={() => _open(false)}
      onOpen={() => _open(true)}
      open={open}
      size='small'
      className="disabledCardTooltip"
    >
      {!fieldEditorEnabled && <UpgradeTooltip />}
      <Header>Layout and Fields</Header>
      <Modal.Content scrolling>
        {!fieldEditorEnabled && <div className={styles.disabledFieldDialogOverlay}>
          <div className={styles.disabledFieldDialogLock}><Icon name='lock' /></div>
        </div>}
        <Message header='Tips' list={[
          'Drag and drop fields to reorder them in the widget',
          'Include an email field to enable replying to the notification email'
        ]} />

        {!fields.some((field) => availableFields[field.parent].type === 'email') && <Message negative>
          <p>You won&apos;t able to respond to notification emails without including an email field </p>
        </Message>}

        {fields.filter((field) => availableFields[field.parent].type === 'email').length > 1 && <Message warning>
          <p>Multiple email fields detected so the first will be used as the Reply-To address</p>
        </Message>}
        <div className={styles.widgetWireframe}>
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={fields.map((_field, index) => (index + 1))} strategy={verticalListSortingStrategy}>
              {fields.length < 1 && 'Save to reset to default fields'}
              {fields.map((field, index) => {
                return <SortableItem key={`${field.id}-${index}`} id={(index + 1)} fieldId={field.parent} withoutSegment>
                  <div className={styles.fieldItem}>
                    <Label color='grey' horizontal basic style={{ minWidth: '6em' }} >
                      {availableFields[field.parent].name}
                    </Label> <span>{field.label}</span>
                    <Button icon size='mini' basic onClick={() => handleFieldDelete(field.id)}>
                      <Icon name='delete' />
                    </Button>
                  </div>
                </SortableItem>
              })}
            </SortableContext>
          </DndContext>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleAddNewField()} disabled={!fieldEditorEnabled}>
          <Icon name='plus' /> Insert field
        </Button>
        <Button onClick={() => handleFieldsSave()} disabled={busy || !fieldEditorEnabled} loading={busy}>
          <Icon name='checkmark' /> {isChanged ? 'Save' : 'Close'}
        </Button>
      </Modal.Actions>
      {openNewFieldModal && <NewFieldModal open={openNewFieldModal} setOpen={setOpenNewFieldModal} fields={availableFields} update={handleFieldsUpdate} />}
    </Modal>
  )
}

FieldsModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  handleOpen: PropTypes.func
}

function SortableItem (props) {
  const sortableObj = {
    id: props.id
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable(sortableObj)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative'
  }

  return (
    <div ref={setNodeRef} style={style} >
      <Segment style={{ marginBottom: '1em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Icon color='grey' {...attributes} {...listeners} name='ellipsis vertical' size='large' style={{ minWidth: '2em', cursor: 'grab' }} />
        {props.children}
      </Segment>
    </div>
  )
}

SortableItem.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number
}
