import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { buildHttpRpcRequest, cognitoUserPoolTransferHandler, defaultConfig, getSharedHeaders, } from './base';
import { parseJsonBody, parseJsonError, } from '@aws-amplify/core/internals/aws-client-utils';
import { assertServiceError } from '../../../../../errors/utils/assertServiceError';
import { AuthError } from '../../../../../errors/AuthError';
const buildUserPoolSerializer = (operation) => (input, endpoint) => {
    const headers = getSharedHeaders(operation);
    const body = JSON.stringify(input);
    return buildHttpRpcRequest(endpoint, headers, body);
};
const buildUserPoolDeserializer = () => {
    return async (response) => {
        if (response.statusCode >= 300) {
            const error = await parseJsonError(response);
            assertServiceError(error);
            throw new AuthError({ name: error.name, message: error.message });
        }
        else {
            const body = await parseJsonBody(response);
            return body;
        }
    };
};
const handleEmptyResponseDeserializer = () => {
    return async (response) => {
        if (response.statusCode >= 300) {
            const error = await parseJsonError(response);
            assertServiceError(error);
            throw new AuthError({ name: error.name, message: error.message });
        }
        else {
            return undefined;
        }
    };
};
export const initiateAuth = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('InitiateAuth'), buildUserPoolDeserializer(), defaultConfig);
export const revokeToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('RevokeToken'), buildUserPoolDeserializer(), defaultConfig);
export const signUp = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('SignUp'), buildUserPoolDeserializer(), defaultConfig);
export const confirmSignUp = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmSignUp'), buildUserPoolDeserializer(), defaultConfig);
export const forgotPassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgotPassword'), buildUserPoolDeserializer(), defaultConfig);
export const confirmForgotPassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmForgotPassword'), buildUserPoolDeserializer(), defaultConfig);
export const respondToAuthChallenge = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('RespondToAuthChallenge'), buildUserPoolDeserializer(), defaultConfig);
export const resendConfirmationCode = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ResendConfirmationCode'), buildUserPoolDeserializer(), defaultConfig);
export const verifySoftwareToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifySoftwareToken'), buildUserPoolDeserializer(), defaultConfig);
export const associateSoftwareToken = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('AssociateSoftwareToken'), buildUserPoolDeserializer(), defaultConfig);
export const setUserMFAPreference = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('SetUserMFAPreference'), buildUserPoolDeserializer(), defaultConfig);
export const getUser = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUser'), buildUserPoolDeserializer(), defaultConfig);
export const changePassword = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ChangePassword'), buildUserPoolDeserializer(), defaultConfig);
export const confirmDevice = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ConfirmDevice'), buildUserPoolDeserializer(), defaultConfig);
export const forgetDevice = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ForgetDevice'), handleEmptyResponseDeserializer(), defaultConfig);
export const deleteUser = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUser'), handleEmptyResponseDeserializer(), defaultConfig);
export const getUserAttributeVerificationCode = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GetUserAttributeVerificationCode'), buildUserPoolDeserializer(), defaultConfig);
export const globalSignOut = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('GlobalSignOut'), buildUserPoolDeserializer(), defaultConfig);
export const updateUserAttributes = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateUserAttributes'), buildUserPoolDeserializer(), defaultConfig);
export const verifyUserAttribute = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('VerifyUserAttribute'), buildUserPoolDeserializer(), defaultConfig);
export const updateDeviceStatus = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('UpdateDeviceStatus'), buildUserPoolDeserializer(), defaultConfig);
export const listDevices = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('ListDevices'), buildUserPoolDeserializer(), defaultConfig);
export const deleteUserAttributes = composeServiceApi(cognitoUserPoolTransferHandler, buildUserPoolSerializer('DeleteUserAttributes'), buildUserPoolDeserializer(), defaultConfig);
