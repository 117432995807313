import ActionTypes from '../actions/action-types'

const initialState = {
  id: '',
  plan: {
    nextPayment: 0,
    now: 0,
    forms: 1,
    messages: 0,
    price: 0,
    name: '',
    cancelling: false,
    planLoaded: false,
    planMissing: false,
    enabled: true
  },
  payment: {
    expiry: '',
    last4: '',
    type: '',
    paymentLoaded: false
  },
  invoices: {
    invoicesLoaded: false,
    invoices: []
  },
  abilities: {
    abilitiesLoaded: false,
    abilities: []
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ACCOUNT.SUBSCRIPTION_GET:
      action.payload.plan.planLoaded = true
      return { ...state, ...action.payload }
    case ActionTypes.ACCOUNT.SUBSCRIPTION_NOT_FOUND:
      state.plan.planMissing = true
      state.plan.planLoaded = false
      return { ...state }
    case ActionTypes.ACCOUNT.SUBSCRIPTION_ABILITIES_GET:
      state.abilities.abilities = action.payload
      state.abilities.abilitiesLoaded = true
      return { ...state }
    case ActionTypes.ACCOUNT.SUBSCRIPTION_PAYMENT_GET: {
      action.payload.paymentLoaded = true
      state.payment = action.payload
      return { ...state }
    }
    case ActionTypes.ACCOUNT.SUBSCRIPTION_INVOICES_GET: {
      state.invoices.invoicesLoaded = true
      state.invoices.invoices = action.payload
      return { ...state }
    }
    default:
      return state
  }
}
