import { Button, Container, Image, Menu, Icon, Dropdown, Label } from 'semantic-ui-react'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signOut } from '../../actions/account-actions'
import styles from './styles.less'
import { Tooltip } from 'react-tooltip'
import CFTheme from '../../constants/cf-theme.less'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { signOut, gravatarHash, max, count } = this.props
    const remaining = max - count

    return <div>
      <Menu fixed='top' borderless className={styles.topnav}>
        <Container>
          <Menu.Item as={Link} to='/' className={styles.item}>
            <Image size='small' src='https://assets.contactform.pro/images/CF_Logo_Full_BlackMed_Small.png' style={{ width: '170px' }} />
          </Menu.Item>
          {/* <div className={styles.verticalBar}></div> */}
          <Menu.Menu fixed='top' position='right' >
            <Menu.Item as={Link} to='/' className={styles.item}>
              <Button color='yellow' size='small' basic style={{ fontSize: '.928571rem' }}>Forms</Button>
            </Menu.Item>
            <Menu.Item className={styles.item}>
              <Button as='div' labelPosition='right' size='small' style={{ fontSize: '.928571rem' }} className="creditRemaining">
                <Button icon style={{ fontSize: '.928571rem' }} basic>
                  Credits
                </Button>
                <Label basic pointing='left' style={{ fontSize: '.928571rem' }}>
                  {remaining}
                </Label>
              </Button>
            </Menu.Item>
            <Dropdown icon={null} trigger={<Menu.Item className={styles.item}>
              <div className={styles.avatar}></div>
              <Icon name='chevron down' />
            </Menu.Item>}>
              <Dropdown.Menu>
                <Dropdown.Item text={gravatarHash} disabled />
                <Menu.Item as={Link} to='/account'>Account</Menu.Item>
                <Menu.Item as={Link} to='/billing'>Billing</Menu.Item>
                <Dropdown.Item text='Sign out' onClick={signOut} />
                <Dropdown.Divider />
                <Menu.Item href='https://contactform.pro/help/' target='_blank' className={styles.item}>Help</Menu.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Container>
      </Menu>
      <Tooltip
        anchorSelect=".creditRemaining"
        style={{ opacity: 1 }}
        clickable
        className={CFTheme.cfTooltipCyan}
        classNameArrow={CFTheme.cfTooltipCyanArrow}
        content={
          <div>Remaining message credits for this billing period</div>} />
    </div>
  }
}

Header.propTypes = {
  signOut: PropTypes.func,
  gravatarHash: PropTypes.string,
  max: PropTypes.number,
  count: PropTypes.number
}

const mapStateToProps = ({ account, form }) => {
  const { gravatarHash } = account.user
  const { max, count } = form.stats
  return ({
    gravatarHash,
    max,
    count
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signOut }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header)
