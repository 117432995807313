.enticeContainer {
    position: relative;
    min-height: 20em;
    z-index: 5;
    display: flex;
    flex-direction: column;
}

.widgetContainer {
    display: flex;
    flex-direction: row-reverse;
}

.widgetIcon {
    z-index: 99999;
    cursor: not-allowed;
    border-radius: 20%;
    width: 60px;
    height: 60px;
    background-color: grey;

    div {
        background-image: url('https://assets.contactform.pro/icons/open/14/icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        border-radius: 20%;
        width: 100%;
        height: 100%;
    }
}

.entice {
    text-align: right;
    z-index: 99999;
    font-family: sans-serif;
}

.enticeItems {
    margin-bottom: 1em;
}

.enticeAction,
.enticeMessage {
    float: right;
    clear: right;
    margin: 0.8em 0.4em;
    padding: 1em;
    font-size: 14px;
}

.enticeAction {
    color: #fff;
    background: #acacac;
    border-radius: 0.3em;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

.enticeMessage {
    background: #ececec;
    border-radius: 1em;
}

.enticeItem {
    display: flex;
    align-items: center;
}

.disabledEnticeDialogOverlay {
    z-index: 9;
    display: flex;
    background: #cecece96;
    position: absolute;
    inset: 0;
    justify-content: center;
    align-items: center;
}

.disabledEnticeDialogLock {
    position: absolute;
    right: 1em;
    top: 1em;
    font-size: 30px;
}