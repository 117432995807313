import { currentUserToken, signIn } from './account-actions'
import axios from 'axios'
import { store } from '../main'

const isDev = process.env.IS_DEV

const instance = axios.create({
  baseURL: isDev ? 'http://localhost:3001' : 'https://api.contactform.pro',
  timeout: 3500
})

instance.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response && error.response.status && error.response.status === 401) {
    store.dispatch(signIn())
  } else {
    throw error
  }
})

export default {
  GETv2: function (path = '/', options = {}) {
    return new Promise((resolve, reject) => {
      return currentUserToken().then((token) => {
        const opts = {
          headers: {
            accesstoken: token
          },
          ...options
        }

        instance({
          method: 'get',
          url: path,
          ...opts
        })
          .then(response => resolve(response.data || response))
          .catch(error => reject(error))
      })
    })
  },
  PUTv2: function (path = '/', options = {}) {
    return new Promise((resolve, reject) => {
      return currentUserToken().then((token) => {
        const opts = {
          headers: {
            accesstoken: token
          },
          ...options
        }

        instance({
          method: 'put',
          url: path,
          ...opts
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    })
  },
  POSTv2: function (path = '/', options = {}) {
    return new Promise((resolve, reject) => {
      return currentUserToken().then((token) => {
        let headers = {
          accesstoken: token
        }
        if (options.headers) {
          const extraHeaders = options.headers
          headers = { ...headers, ...extraHeaders }
          delete options.headers
        }
        const opts = {
          headers,
          ...options
        }

        instance({
          method: 'post',
          url: path,
          ...opts
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    })
  },
  DELETEv2: function (path = '/', options = {}) {
    return new Promise((resolve, reject) => {
      return currentUserToken().then((token) => {
        const opts = {
          headers: {
            accesstoken: token
          },
          ...options
        }

        instance({
          method: 'delete',
          url: path,
          ...opts
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    })
  }
}
