import { Button, Divider, Modal } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import CFTheme from '../../../constants/cf-theme.less'

const isDev = process.env.IS_DEV

export default function PlanSelector (props) {
  const { plan } = useSelector((state) => state.subscription)
  const { user } = useSelector((state) => state.account)
  const [open, setOpen] = useState(false)
  const storageKey = 'pro.contactform.app.welcome.plan'
  const revision = 1

  useEffect(() => {
    if (user.newUser && plan.planMissing) {
      const planSelectorState = localStorage.getItem(storageKey)
      if (!planSelectorState || planSelectorState < revision) {
        setOpen(true)
      }
    }
  }, [user.newUser, plan.planMissing])

  const handleDismiss = () => {
    localStorage.setItem(storageKey, revision)
    setOpen(false)
  }

  const pricing = isDev
    ? <stripe-pricing-table pricing-table-id="prctbl_1LksmSCRTL5sPHZXK5Jldu9g" publishable-key="pk_test_51LYDq9CRTL5sPHZXcoO1cL8arTBhVUW3xWtqU1KN2kcddkIZgwatuTX76az2zu3ngjWGz1ZjlQiOfHC6dI2soHix002dvLbIUm" client-reference-id={user.sub}></stripe-pricing-table>
    : <stripe-pricing-table pricing-table-id="prctbl_1MPPofCRTL5sPHZX9gTi3Ic6" publishable-key="pk_live_51LYDq9CRTL5sPHZXHRYg1AfffHuRS8dDuysFiXDt3brbxAvYMq9MTvrwKXsC3giwD4CWIMN0UaWB2VZfhds6DpcF00PQF50Zpm" client-reference-id={user.sub}></stripe-pricing-table>

  return (<>
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='large'
      data-cy='cf-plan-modal'
    >
      <Modal.Header>Select a plan</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {pricing}
          <Divider horizontal>Or</Divider>
          <p style={{ textAlign: 'center' }}>If you would like to use ContactForm.Pro with limited features, choose our <Button compact onClick={() => handleDismiss()} className={CFTheme.cfButtonYellow}>Free plan</Button></p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => handleDismiss()}>
          Later
        </Button>
      </Modal.Actions>
    </Modal>
  </>)
}

PlanSelector.propTypes = {
  onClose: PropTypes.func,
  newUser: PropTypes.bool,
  stripeId: PropTypes.string
}
