import './semantic-css'
import 'react-tooltip/dist/react-tooltip.css'

import { applyMiddleware, createStore } from 'redux'

import App from './App'
import { Provider } from 'react-redux'
import React from 'react'
import logger from 'redux-logger'
import { render } from 'react-dom'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import { BrowserRouter as Router } from 'react-router-dom'
import { datadogLogs } from '@datadog/browser-logs'

const isDev = process.env.IS_DEV
const middlewares = [thunk]
if (isDev) {
  middlewares.push(logger)
}
const store = createStore(rootReducer, applyMiddleware(...middlewares))

if (!isDev) {
  datadogLogs.init({
    clientToken: 'pub18fd89602f6ade06f2c90a93e564e836',
    site: 'datadoghq.eu',
    service: 'ui-dashboard',
    forwardErrorsToLogs: true,
    sampleRate: 100
  })
}

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

export { store }
