.topnav {
    font-size: 1.1em !important;
}

.item:hover {
    background: inherit !important
}

.avatar {
    height: 26px;
    width: 26px;
    background-size: cover;
    border-radius: 100%;
    background-image: url('https://assets.contactform.pro/images/CF_ProfilePicture.svg');
}

.verticalBar {
    background: #a7a7a7;
    width: 1px;
    margin: 10px;
}