import React, { useState, useEffect } from 'react'
import { Modal, Header, Select, Input, Button, Checkbox, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import validator from 'validator'
import { InputCharacterCount } from '../../common/input-char-counter'

export default function NewFieldModal (props) {
  const [type, setType] = useState(null)
  const [label, setLabel] = useState('')
  const [required, setRequired] = useState(false)
  const [characterLimit, setCharacterLimit] = useState(0)

  const handleInsert = function () {
    const newField = {
      label,
      parent: type,
      required
    }
    props.update(newField)
  }

  const insertCondition = (label !== '') && (type !== null)

  useEffect(() => {
    const field = props.fields.find(field => field.id === type)
    if (field) {
      setCharacterLimit(field.limit)
    }
  }, [type])

  return <Modal
    open={props.open}
    onClose={() => props.setOpen(false)}
    size='mini'
  >
    <Header>Insert field</Header>
    <Modal.Content>
      <Select basic fluid placeholder='Choose a field' options={props.fields.map((field) => {
        return { key: field.id, value: field.id, text: field.name }
      })} onChange={(_event, data) => setType(data.value)} />
      <div style={{ margin: '1em 0' }}>
        {type !== null && <Input placeholder='Label' fluid onChange={(_event, data) => setLabel(validator.trim(data.value))} maxLength={characterLimit} icon={<InputCharacterCount max={characterLimit} count={label.length} />} />}
      </div>
      <div style={{ margin: '1em 0' }}>
        {type !== null && <Checkbox toggle label='Required' onChange={(_event, data) => setRequired(data.value)} disabled checked/>}
      </div>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => props.setOpen(false)}>
        Cancel
      </Button>
      <Button onClick={() => handleInsert()} disabled={!insertCondition}>
        <Icon name='checkmark' /> Insert
      </Button>
    </Modal.Actions>
  </Modal>
}

NewFieldModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.object),
  update: PropTypes.func
}
