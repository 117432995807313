.cards {
    :global(.card) {
        margin: .875em 3em !important;
    }
}

.emptyState {
    text-align: center;

    object {
        max-width: 200px;
        padding-bottom: 20px;
    }

    p,
    h3,
    button {
        margin: 6px 0px !important;
    }
}