import ActionTypes from '../actions/action-types'

const initialState = {
  user: {
    email: '',
    sub: '',
    jwtToken: '',
    gravatarHash: '',
    terms: true,
    newUser: false
  },
  emails: [],
  loggedIn: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ACCOUNT.UPDATE_USER_INFO:
      return { ...state, user: action.user, loggedIn: true }
    case ActionTypes.ACCOUNT.EMAILS_GET:
      return { ...state, emails: action.payload }
    default:
      return state
  }
}
