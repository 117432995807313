import React, { useEffect } from 'react'
import { Button, Header, Icon, Modal, List, Divider, Label, Message } from 'semantic-ui-react'
import styles from './installation/styles.less'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import { useSelector, useDispatch } from 'react-redux'
import { verifyFormDomainAPI } from '../../../actions/form-actions'

function InstallModal (props) {
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState('')
  const [verifyError, setVerifyError] = React.useState(false)
  const [verifyBusy, setVerifyBusy] = React.useState(false)
  const { currentForm } = useSelector((state) => state.form)
  const dispatch = useDispatch()

  const handleSelect = (event) => {
    event.target.select()
  }

  const handleCopy = () => {
    switch (type) {
      case 'wordpress':
        copy(props.id)
        break
      case 'html':
      default:
        copy(`<script src="https://embed.contactform.pro/cf.js" data-cf="${props.id}" async></script>`)
        break
    }
  }

  useEffect(() => {
    if (props.open) {
      setOpen(true)
    }
  }, [props.open])

  const _open = (state) => {
    props.handleInstallModalState(state)
    setOpen(state)
  }

  const verifyDomainOrClose = () => {
    if (currentForm.verified) {
      _open(false)
      return
    }
    setVerifyBusy(true)
    setVerifyError(false)
    dispatch(verifyFormDomainAPI(props.id)).then(() => {
      _open(false)
    }).catch(() => {
      setVerifyError(true)
      setVerifyBusy(false)
    })
  }

  const typeSelector = <Modal.Content>
    <p>How would you like to add ContactForm.Pro to <b>{currentForm.name}</b>?</p>
    <div style={{ padding: '1em 0', textAlign: 'center' }}>
      <Button basic onClick={() => setType('html')} data-cy="cf-install-modal-html"><Icon name='code' /> Insert HTML</Button>
      <Button basic onClick={() => setType('wordpress')} data-cy="cf-install-modal-wordpress"><Icon name='wordpress simple' /> Wordpress Plugin</Button>
    </div>
    <p style={{ fontSize: 'smaller' }}>Choose Insert HTML unless your website runs on WordPress.</p>
  </Modal.Content>

  const htmlType = <Modal.Content>
    <p>Copy this code and paste it into your website&apos;s <code>{'<head>'}</code> section.</p>
    <div style={{ padding: '1em 0' }}>
      <textarea onClick={handleSelect}
        className={styles.code}
        readOnly
        value={`<script src="https://embed.contactform.pro/cf.js" data-cf="${props.id}" async></script>`}>
      </textarea>
    </div>
    <p>Once the code is on your website, click verify so we can confirm <b>{currentForm.name}</b> is working correctly.</p>
  </Modal.Content>

  const wordpressType = <Modal.Content>
    <h4>Install our <a href='https://wordpress.org/plugins/contactformpro/' target="_blank" rel="noreferrer">Wordpress Plugin</a> in three easy steps:</h4>
    <List ordered>
      <List.Item>Visit the Plugins page in your Wordpress Dashboard</List.Item>
      <List.Item>Click <b>Add new</b> and search for <b>ContactForm.Pro</b></List.Item>
      <List.Item>Install and then activate the plugin from the Plugins page</List.Item>
    </List>
    <p style={{ fontSize: 'smaller' }}>Alternatively, you can <a href='https://wordpress.org/plugins/contactformpro/' target="_blank" rel="noreferrer">download</a> our plugin and upload it to your <code>/wp-content/plugins/</code> directory. You can then activate from the Plugins page.</p>
    <Divider />
    <p>Once activated, click the <b>Settings</b> link in the plugin. Copy the key below and paste into the <b>Form key</b> field within the plugin settings.</p>
    <div style={{ padding: '1em 0' }}>
      <textarea onClick={handleSelect}
        className={styles.code}
        readOnly
        value={props.id}
        rows={1}>
      </textarea>
    </div>
    <p>Finally, click verify so we can confirm <b>{currentForm.name}</b> is working correctly.</p>
  </Modal.Content>

  const determineType = () => {
    switch (type) {
      case 'wordpress':
        return wordpressType
      case 'html':
      default:
        return htmlType
    }
  }

  return (
    <Modal
      onClose={() => _open(false)}
      onOpen={() => _open(true)}
      open={open}
      size='tiny'
      data-cy="cf-install-modal"
    >
      <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Add to {currentForm.name}</div>
        {currentForm.verified
          ? <Label color='green'>Verified</Label>
          : <Label color='red'>Unverified</Label>
        }
      </Header>

      {type === ''
        ? typeSelector
        : determineType()}
      {verifyError && <Modal.Content>
        <Message negative>
          <Message.Header>Unable to verify</Message.Header>
          <p>If you have installed ContactForm.Pro and are seeing this in error, please contact us.</p>
        </Message>
      </Modal.Content>}
      <Modal.Actions>
        {type !== '' && <Button basic onClick={() => setType('')} data-cy="cf-install-modal-back">
          Back
        </Button>}
        {type !== '' && <Button basic onClick={() => handleCopy()} data-cy="cf-install-modal-copy">
          <Icon name='copy outline' /> Copy
        </Button>}

        {type !== '' && <Button disabled={verifyBusy} loading={verifyBusy} color='green' onClick={() => verifyDomainOrClose()} data-cy="cf-install-modal-done">
          <Icon name='checkmark' /> {currentForm.verified ? 'Done' : 'Verify'}
        </Button>}

        {type === '' && <Button basic onClick={() => _open(false)} >
          Later
        </Button>}
      </Modal.Actions>
    </Modal>
  )
}

InstallModal.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool,
  handleInstallModalState: PropTypes.func
}

export default InstallModal
