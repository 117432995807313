function toDateTime (secs) {
  const t = new Date(1970, 0, 1)
  t.setSeconds(secs)
  return t
}

function toShortDate (date) {
  return date.getDate() +
  '/' + (date.getMonth() + 1) +
  '/' + date.getFullYear()
}

export { toDateTime, toShortDate }
