import ActionTypes from '../actions/action-types'

const initialState = {
  forms: [],
  currentForm: {
    loading: true,
    fields: [],
    enticeSet: []
  },
  allDisabled: false,
  busy: false,
  stats: {
    max: 0,
    count: 0
  },
  availableFields: [],
  availableEnticeItems: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FORM.GET_ALL: {
      const allDisabled = action.payload.every(form => form.enabled === false)
      return { ...state, forms: action.payload, allDisabled }
    }
    case ActionTypes.FORM.GET:
      return { ...state, currentForm: action.payload }
    case ActionTypes.FORM.BUSY:
      return { ...state, busy: action.payload }
    case ActionTypes.FORM.GET_STATS:
      return { ...state, stats: action.payload }
    case ActionTypes.FORM.GET_AVAILBLE_FIELDS:
      return { ...state, availableFields: action.payload }
    case ActionTypes.FORM.GET_FIELDS_LAYOUT: {
      const processed = action.payload.map((field, index) => {
        return { ...field, id: index }
      })
      state.currentForm.fields = processed
      return { ...state }
    }
    case ActionTypes.FORM.GET_AVAILBLE_ENTICE_TYPES: {
      return { ...state, availableEnticeItems: action.payload }
    }
    case ActionTypes.FORM.GET_ENTICE_TYPES: {
      const processed = action.payload.map((field, index) => {
        return { ...field, id: index }
      })
      state.currentForm.enticeSet = processed
      return { ...state }
    }
    default:
      return state
  }
}
