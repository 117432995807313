/* eslint-disable no-return-assign */
import { Button, Card, Confirm, Dropdown, Label } from 'semantic-ui-react'
import { deleteFormAPI, getFormsAPI } from '../../../actions/form-actions'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styles from './styles.less'
import { toShortDate } from '../../../utilities/date'
import EmptyBoxImage from '../../../images/empty_box.svg'
import CreateForm from '../../dialogs/create'

class FormList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      deleteConfirmOpen: false,
      formDeletionId: '',
      deleteBusy: false
    }
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this)
    this.deleteForm = this.deleteForm.bind(this)
  }

  componentDidMount () {
    const { getFormsAPI } = this.props
    getFormsAPI()
  }

  deleteForm () {
    const { deleteFormAPI, getFormsAPI } = this.props
    const { formDeletionId } = this.state

    this.setState({
      deleteBusy: true
    })

    deleteFormAPI(formDeletionId).then(() => {
      getFormsAPI().then(() => {
        this.setState({
          deleteBusy: false,
          deleteConfirmOpen: false
        })
      })
    })
  }

  openDeleteConfirm (event, id) {
    event.preventDefault()
    this.setState((prevState) => ({
      deleteConfirmOpen: !prevState.deleteConfirmOpen,
      formDeletionId: id
    }))
  }

  render () {
    const { deleteConfirmOpen, deleteBusy } = this.state
    const { openDeleteConfirm, deleteForm } = this
    const { forms } = this.props

    return <>
      <Card.Group centered className={styles.cards} data-cy="cf-card-list">
        {forms.map((form) => {
          const { name, id, created, color, enabled } = form
          const createDate = new Date(created)

          const sshot = `//image.thum.io/get/width/300/crop/800/http://${name}`
          return <div key={id}>
            <Card style={{ boxShadow: `0 0 0 1px #d4d4d5, 0 2px 0 0 ${color}, 0 1px 3px 0 #d4d4d5` }}>
              <div style={{ height: '200px', width: '100%', backgroundSize: 'cover', backgroundImage: `url('${sshot}')` }}></div>
              <Card.Content>
                <Card.Header>{name}</Card.Header>
              </Card.Content>
              <Card.Content extra>
                <div className='ui two buttons'>
                  <Button basic color='grey' as={Link} to={`/forms/edit/${id}`} data-cy='cf-card-edit'>Edit</Button>
                  <Dropdown button basic text='Options' className='grey'>
                    <Dropdown.Menu>
                      <Dropdown.Item text='Remove' onClick={(event) => this.openDeleteConfirm(event, id)} />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Card.Content>
              <Card.Content extra>
                Created {toShortDate(createDate)}
                <div style={{ float: 'right', marginTop: '-2px' }}>
                  {enabled ? <Label size="mini" color={'green'}>Enabled</Label> : <Label size="mini" color={'red'}>Disabled</Label>}
                </div>
              </Card.Content>
            </Card>
          </div>
        })}
        {forms.length < 1 && <div className={styles.emptyState}>
          <object data={EmptyBoxImage}></object>
          <h3>Looks like you&apos;re new!</h3>
          <p>Create a form to begin using ContactForm on your website.</p>
          <CreateForm />
        </div>}
      </Card.Group>

      <Confirm
        open={deleteConfirmOpen}
        confirmButton={<Button loading={deleteBusy} disabled={deleteBusy}>Yes</Button>}
        header='Delete form'
        onCancel={(event) => openDeleteConfirm(event, false)}
        onConfirm={() => deleteForm()}
      />
    </>
  }
}

FormList.propTypes = {
  getFormsAPI: PropTypes.func,
  deleteFormAPI: PropTypes.func,
  forms: PropTypes.array
}

const mapStateToProps = ({ form: { forms } }) => {
  return ({ forms })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getFormsAPI, deleteFormAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FormList)
