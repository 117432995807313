import ActionTypes from './action-types'
import http from './http'
import axios from 'axios'

export function getIconsAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/assets/icons').then((response) => {
        dispatch({
          type: ActionTypes.ASSETS.GET_ICONS,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getIconsJSON () {
  return new Promise((resolve, reject) => {
    return axios.get('//assets.contactform.pro/icons/open/manifest.json').then((response) => {
      resolve(response.data)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function getCloseIconsJSON () {
  return new Promise((resolve, reject) => {
    return axios.get('//assets.contactform.pro/icons/close/manifest.json').then((response) => {
      resolve(response.data)
    }).catch((e) => {
      reject(e)
    })
  })
}
