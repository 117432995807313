import React, { useState, useEffect } from 'react'
import { Modal, Header, Select, Input, Button, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import validator from 'validator'
import { parseDomain, fromUrl } from 'parse-domain'
import { InputCharacterCount } from '../../common/input-char-counter'

export default function NewEnticeItemModal (props) {
  const [type, setType] = useState(null)
  const [value, setValue] = useState('')
  const [urlLabel, setUrlLabel] = useState('')
  const [condition, setCondition] = useState(false)
  const [placeholder, setPlaceholder] = useState('')
  const [valueCharLimit, setValueCharLimit] = useState(0)
  const [urlLabelCharLimit, setLabelUrlCharLimit] = useState(0)

  const handleInsert = function () {
    const newItem = {
      value: type === 1 ? `${window.btoa(value)},${window.btoa(urlLabel)}` : value,
      parent: type
    }

    props.update(newItem)
  }

  useEffect(() => {
    switch (type) {
      case 0: {
        setPlaceholder('Message')
        setCondition((value !== '') && (type !== null))
        const item = props.enticeSet.find((item) => item.id === type)
        if (item && item.limit && typeof item.limit === 'number') {
          setValueCharLimit(item.limit)
        }
        break
      }
      case 1: {
        const domain = parseDomain(fromUrl(value))
        const isUrl = domain.type === 'LISTED' && domain.domain !== undefined
        setPlaceholder('URL')
        setCondition((value !== '') && (type !== null) && isUrl)

        const item = props.enticeSet.find((item) => item.id === type)
        if (item && item.limit && Object.keys(item.limit)) {
          setValueCharLimit(item.limit.url)
          setLabelUrlCharLimit(item.limit.label)
        }
        break
      }
    }
  }, [value, type])

  return <Modal
    open={props.open}
    onClose={() => props.setOpen(false)}
    size='mini'
  >
    <Header>Insert entice item</Header>
    <Modal.Content>
      <Select basic fluid placeholder='Choose a type' options={props.enticeSet.map((enticeItem) => {
        return { key: enticeItem.id, value: enticeItem.id, text: enticeItem.name }
      })} onChange={(_event, data) => setType(data.value)} />
      <div style={{ margin: '1em 0' }}>
        {type === 1 && <Input placeholder="Label" fluid onChange={(_event, data) => setUrlLabel(validator.trim(data.value))} maxLength={urlLabelCharLimit} icon={<InputCharacterCount max={urlLabelCharLimit} count={urlLabel.length} />} />}
      </div>
      <div style={{ margin: '1em 0' }}>
        {type !== null && <Input placeholder={placeholder} fluid onChange={(_event, data) => setValue(validator.trim(data.value))} maxLength={valueCharLimit} icon={<InputCharacterCount max={valueCharLimit} count={value.length} />} />}
      </div>

    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => props.setOpen(false)}>
        Cancel
      </Button>
      <Button onClick={() => handleInsert()} disabled={!condition}>
        <Icon name='checkmark' /> Insert
      </Button>
    </Modal.Actions>
  </Modal>
}

NewEnticeItemModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  enticeSet: PropTypes.arrayOf(PropTypes.object),
  update: PropTypes.func
}
