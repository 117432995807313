import { Amplify } from 'aws-amplify'
import { Hub } from 'aws-amplify/utils'
import {
  fetchAuthSession,
  signOut as signOut_,
  signInWithRedirect
} from 'aws-amplify/auth'

import ActionTypes from './action-types'
import http from './http'
import { store } from '../main'

const isDev = process.env.IS_DEV

Amplify.configure({
  Auth: {
    userPoolWebClientId: '3kgr0hs78lr9uleiekkosh2cb8',
    region: 'eu-west-2',
    mandatorySignIn: false,
    Cognito: {
      userPoolId: 'eu-west-2_21GcXwqdw',
      userPoolClientId: '3kgr0hs78lr9uleiekkosh2cb8',
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: 'signin.contactform.pro',
          scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [isDev ? 'https://localhost:1234' : 'https://app.contactform.pro'],
          redirectSignOut: [isDev ? 'https://localhost:1234' : 'https://app.contactform.pro'],
          responseType: 'code'
        }
      }
    }
  }
})

const currentAuthenticatedUser = () => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const { tokens } = await fetchAuthSession()
      return resolve({
        attributes: {
          email: tokens.idToken.payload.email,
          sub: tokens.idToken.payload.sub
        },
        signInUserSession: {
          idToken: {
            jwtToken: tokens.idToken.toString()
          }
        }
      })
    } catch (e) {
      return reject(e)
    }
  })
}

const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in')
      break
    case 'signUp':
      console.log('user signed up')
      break
    case 'signOut':
      console.log('user signed out')
      break
    case 'signIn_failure':
      console.log('user sign in failed')
      break
    case 'tokenRefresh':
      console.log('token refresh succeeded')
      break
    case 'tokenRefresh_failure':
      console.log('token refresh failed')
      break
    case 'autoSignIn':
      console.log('Auto Sign In after Sign Up succeeded')
      break
    case 'autoSignIn_failure':
      console.log('Auto Sign In after Sign Up failed')
      break
    case 'configured':
      console.log('the Auth module is configured')
  }
}

Hub.listen('auth', listener)

export function getCurrentUser () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      currentAuthenticatedUser().then(cogUser => {
        const { email, sub } = cogUser.attributes
        const { jwtToken } = cogUser.signInUserSession.idToken
        getOrCreateUserAPI().then((user) => {
          const { gravatarHash, terms, newUser } = user
          dispatch({
            type: ActionTypes.ACCOUNT.UPDATE_USER_INFO,
            user: {
              email, sub, jwtToken, gravatarHash, terms, newUser
            }
          })
          resolve()
        })
      }).catch(() => {
        reject(new Error('Not signed in'))
      })
    })
  }
}

export function getOrCreateUserAPI () {
  return new Promise((resolve, reject) => {
    return http.GETv2('v1/users/getOrCreate').then((response) => {
      resolve(response)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function signIn () {
  return (dispatch) => {
    (async () => {
      await signInWithRedirect()
    })()
  }
}

export function signOut () {
  return (dispatch) => {
    signOut_()
  }
}

export function currentUserToken () {
  return currentAuthenticatedUser().then((user) => {
    return user.signInUserSession.idToken.jwtToken
  }).catch(() => {
    store.dispatch(signIn())
  })
}

export function putUserTermsAPI () {
  return new Promise((resolve, reject) => {
    return http.PUTv2('v1/users/putUserTerms').then((response) => {
      resolve(response)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function getEmailVerificationAPI (email) {
  return new Promise((resolve, reject) => {
    return http.GETv2(`v1/users/email/new/${email}`).then((response) => {
      resolve(response)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function confirmEmailVerificationAPI (email, code) {
  return new Promise((resolve, reject) => {
    return http.PUTv2(`v1/users/email/verify/${email}`, {
      data: {
        code
      }
    }
    ).then((response) => {
      resolve(response)
    }).catch((e) => {
      reject(e)
    })
  })
}

export function getEmailsAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/users/emails').then((response) => {
        dispatch({
          type: ActionTypes.ACCOUNT.EMAILS_GET,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function deleteEmailAPI (emailId) {
  return new Promise((resolve, reject) => {
    return http.DELETEv2(`v1/users/email/delete/${emailId}`).then((response) => {
      resolve(response)
    }).catch((e) => {
      reject(e)
    })
  })
}
