import { Button, Header, Input, Modal } from 'semantic-ui-react'
import { createFormAPI } from '../../../actions/form-actions'

import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { parseDomain, fromUrl } from 'parse-domain'
import withRouter from '../../../utilities/withRouter'
import CFTheme from '../../../constants/cf-theme.less'

class CreateForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      formName: '',
      hostname: '',
      busy: false,
      error: false
    }
  }

  setOpen (open, cancel = false) {
    if (open || cancel) {
      this.setState({
        open,
        formName: ''
      })
    } else {
      const { createFormAPI } = this.props

      this.setState({
        busy: true
      })

      createFormAPI({
        name: this.state.hostname
      }).then((form) => {
        const { id } = form.data
        this.setState({
          busy: false,
          open: false
        }, () => this.props.router.navigate(`/forms/edit/${id}/initial`))
      }).catch((err) => {
        console.error(err)
        this.setState({
          busy: false,
          error: true
        })
      })
    }
  }

  handleNameChange (value) {
    const validator = parseDomain(fromUrl(value.target.value))
    this.setState({
      formName: value.target.value,
      hostname: validator.hostname,
      error: (validator.type !== 'LISTED') || validator.domain === undefined
    })
  }

  render () {
    const { busy, error, formName } = this.state
    const { max, count } = this.props
    const capacity = count >= max
    return (<>
      <Modal
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
        open={this.state.open}
        trigger={<Button className={CFTheme.cfButtonCyan} disabled={capacity} data-cy='cf-create-form' size='medium'>Create form</Button>}
        size='tiny'
        data-cy='cf-create-modal'
      >
        <Modal.Header>Create new contact form</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>Destination website domain</Header>
            <p>Enter the website domain you&apos;d like the form to appear on.</p>
            <div style={{ padding: '1em 0' }}>
              <Input onChange={(input) => this.handleNameChange(input)} placeholder='example.com' fluid error={this.state.error} maxLength={253} />
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='black' onClick={() => this.setOpen(false, true)}>
            Cancel
          </Button>
          <Button
            content="Create"
            labelPosition='right'
            icon='checkmark'
            onClick={() => this.setOpen(false)}
            positive
            loading={busy}
            disabled={formName === '' || busy || error}
          />
        </Modal.Actions>
      </Modal>
    </>)
  }
}

CreateForm.propTypes = {
  createFormAPI: PropTypes.func,
  max: PropTypes.number,
  count: PropTypes.number,
  router: PropTypes.object
}

const mapStateToProps = ({ subscription, form }) => {
  const { plan } = subscription
  const { forms } = form
  return ({
    max: plan.forms,
    count: forms.length
  })
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createFormAPI }, dispatch)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => (
  Object.assign({}, ownProps, stateProps, dispatchProps, {

  })
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateForm))
