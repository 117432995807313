.subtleGrey {
    color: grey
}

.totalLarge {
    font-size: 16px;
    font-weight: bold;
}

.cardDetails {
    font-size: 11px;
}

.cardVendor {
    font-size:20px;
}