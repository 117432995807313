import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../../common/Spinner'
import { Image, Icon, Button, Message } from 'semantic-ui-react'
import * as styles from '../styles.less'
import { putFormConfigAPI } from '../../../../actions/form-actions'
import { useDispatch } from 'react-redux'
const isDev = process.env.IS_DEV
let previewTimout = null

function Preview (props) {
  const dispatch = useDispatch()
  const [busy, setBusy] = useState(false)
  const [enableError, setEnableError] = useState(false)
  const frame = useRef(null)

  const handleUpdate = function () {
    setBusy(true)
    setEnableError(false)
    dispatch(putFormConfigAPI(props.id, { enabled: !props.enabled })).then(() => {
      setBusy(false)
    }).catch(() => {
      setEnableError(true)
      setBusy(false)
    })
  }

  useEffect(() => {
    if (props.cache) {
      const delay = props.entice === true ? 3000 : 1500
      clearTimeout(previewTimout)
      previewTimout = setTimeout(() => {
        if (frame && frame.current) {
          frame.current.contentWindow.postMessage('CF_PRO_APP_PREVIEW', '*')
          frame.current.contentWindow.postMessage('CF_PRO_APP_OPEN', '*')
        }
      }, delay)
    }
  }, [props.cache])

  const previewFrame = `data:text/html;charset=utf-8, 
    <html>
      <head><title>${props.cache || ''}</title>
        <style>
          html, body {
            margin: 0px;
            padding: 0px;
          }
          .cf-widget {
            height: 100% !important;
          }
        </style>
      </head>
      <body>
        <script src="https://embed.contactform.pro/cf.js" data-cf="${props.id}" async></script>
      </body>
    </html>`
  if (props.id && !props.enabled) {
    return <div className={styles.previewDisabled}>
      <Icon name='hand scissors outline' size='big' /> <p>Form disabled!</p>
      <Button primary onClick={handleUpdate} loading={busy} disabled={busy}>{props.enabled ? 'Disable' : 'Enable'}</Button>
      {enableError
        ? <Message negative size='tiny'>
          <p>Cannot exceed plan form count</p>
        </Message>
        : null}
    </div>
  }
  return (<div data-cf-tour="preview">
    {props.id
      ? <iframe src={isDev ? `http://localhost:1235?id=${props.id}&refresh=${props.cache || ''}` : previewFrame} style={{ width: '100%', height: '510px', borderRadius: '0.5em' }} ref={frame} />
      : <Image size='small' src={Spinner} wrapped />}
  </div>
  )
}

Preview.propTypes = {
  id: PropTypes.string,
  cache: PropTypes.string,
  enabled: PropTypes.bool,
  entice: PropTypes.bool
}

export default React.memo(Preview, (prevProps, nextProps) => prevProps.cache === nextProps.cache)
