.characterCounter {
    cursor: default;
    text-align: center;
    opacity: .5;
    width: 2.67143em;
    height: 100%;
    margin: 0;
    line-height: 1;
    transition: opacity .3s;
    position: absolute;
    top: 0;
    right: 0;

    & div {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
}