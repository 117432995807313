import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Checkbox } from 'semantic-ui-react'
import { putUserTermsAPI, getCurrentUser } from '../../actions/account-actions'
import styles from './styles.less'

const Blanket = function (props) {
  const { user } = useSelector((state) => state.account)
  const hasAccepted = user.terms
  const [accepted, setAccepted] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const dispatch = useDispatch()

  const handleTermsAgreement = function (event, data) {
    setDisabled(true)
    putUserTermsAPI().then(() => {
      dispatch(getCurrentUser())
    })
  }

  const handleCheckbox = function (event, data) {
    setAccepted(data.checked)
  }

  return <>{!props.enabled && hasAccepted
    ? null
    : <div className={styles.blanket}>
      Loading...
      <Modal
        header='Welcome'
        content={<div className='content' data-cy='terms-modal'>
          <p><Checkbox label='I have read and agree to our' onChange={handleCheckbox} /> <a href="https://contactform.pro/terms/" target='_blank' rel="noreferrer">Terms of Service</a>.</p>
          <br />
          <p style={{ fontSize: 'smaller' }}>For details in relation to how we handle the personal information you provide to us, please see our <a href="https://contactform.pro/privacy/" target='_blank' rel="noreferrer">Privacy Policy</a>.</p>
        </div>}
        actions={[{ key: 'done', content: 'Continue', positive: true, disabled: !accepted || disabled, loading: disabled }]}
        onActionClick={handleTermsAgreement}
        open={!hasAccepted}
        size='small'
      />
    </div>}
  </>
}

Blanket.propTypes = {
  enabled: PropTypes.bool
}

export default Blanket
