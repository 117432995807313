import { Card, Checkbox, Button, Message, Grid, Dropdown, Header, Icon } from 'semantic-ui-react'
import React, { useState, useEffect } from 'react'
import { putFormConfigAPI, uploadAvatarAPI } from '../../../../actions/form-actions'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash-es'
import { getIconsJSON, getCloseIconsJSON } from '../../../../actions/asset-actions'
import UpgradeTooltip from '../../../common/UpgradeTooltip'

function AppearanceCards (props) {
  const dispatch = useDispatch()
  const [avatarBusy, setAvatarBusy] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [colorBusy, setColorBusy] = useState(false)
  const [invertColorBusy, setInvertColorBusy] = useState(false)
  const [availableIcons, setAvailableIcons] = useState([])
  const [availableCloseIcons, setAvailableCloseIcons] = useState([])
  const [iconBusy, setIconBusy] = useState(false)
  const [closeIconBusy, setCloseIconBusy] = useState(false)
  const [blanketBusy, setBlanketBusy] = useState(false)
  const { abilities } = useSelector((state) => state.subscription)
  const [iconShapeBusy, setIconShapeBusy] = useState(false)

  useEffect(() => {
    getIconsJSON().then(icons => {
      setAvailableIcons(icons)
    })

    getCloseIconsJSON().then(icons => {
      setAvailableCloseIcons(icons)
    })
  }, [])

  const handleAvatarUpload = function (event) {
    setAvatarBusy(true)
    setUploadError(false)
    const image = event.target.files[0]
    dispatch(uploadAvatarAPI(props.id, image)).then(() => {
      setAvatarBusy(false)
    }).catch(() => {
      setAvatarBusy(false)
      setUploadError(true)
    })
  }

  const handleAvatarRemove = function (event) {
    setAvatarBusy(true)
    dispatch(putFormConfigAPI(props.id, { avatar: false })).then(() => {
      setAvatarBusy(false)
    })
  }

  const handleColorChange = debounce((color) => {
    setColorBusy(true)
    dispatch(putFormConfigAPI(props.id, { color })).then(() => {
      setTimeout(() => setColorBusy(false), 800)
    })
  }, 1000)

  const handleRandomColor = function (event) {
    setColorBusy(true)
    const random = '#' + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, '0')
    dispatch(putFormConfigAPI(props.id, { color: random })).then(() => {
      setTimeout(() => setColorBusy(false), 800)
    })
  }

  const handleColorInvert = function (event, data) {
    setInvertColorBusy(true)
    dispatch(putFormConfigAPI(props.id, { colorInvert: data.checked })).then(() => {
      setInvertColorBusy(false)
    })
  }

  const handleIconChange = function (event, data) {
    setIconBusy(true)
    dispatch(putFormConfigAPI(props.id, { icon: data.value })).then(() => {
      setIconBusy(false)
    })
  }

  const handleCloseIconChange = function (event, data) {
    setCloseIconBusy(true)
    dispatch(putFormConfigAPI(props.id, { closeIcon: data.value })).then(() => {
      setCloseIconBusy(false)
    })
  }

  const handleBlanketChange = function (event, data) {
    setBlanketBusy(true)
    dispatch(putFormConfigAPI(props.id, { blanket: data.checked })).then(() => {
      setBlanketBusy(false)
    })
  }

  const handleIconShapeChange = function (shape) {
    setIconShapeBusy(true)
    dispatch(putFormConfigAPI(props.id, { iconShape: shape })).then(() => {
      setIconShapeBusy(false)
    })
  }

  const iconOptions = availableIcons.map(icon => {
    const url = `https://assets.contactform.pro/icons/open/${icon.id}/icon.svg`
    return {
      key: icon.id,
      text: icon.name,
      value: icon.id,
      content: (
        <Header
          image={<img style={{ filter: 'invert(1)' }} src={url} />}
          content={icon.name}
          subheader={icon.description}
        />
      )
    }
  })

  const closeIconOptions = availableCloseIcons.map(icon => {
    const url = `https://assets.contactform.pro/icons/close/${icon.id}/icon.svg`
    return {
      key: icon.id,
      text: icon.name,
      value: icon.id,
      content: (
        <Header
          image={<img style={{ filter: 'invert(1)' }} src={url} />}
          content={icon.name}
          subheader={icon.description}
        />
      )
    }
  })

  if (props.loading) {
    return null
  }

  const disabledCard = { background: 'rgba(0,0,0,0.05)' }
  const disabledLock = <div style={{ float: 'right' }}><Icon name='lock' /></div>

  const { abilities: planAbilities } = abilities
  const themeEnabled = planAbilities.includes('updateTheme')
  const openIconEnabled = planAbilities.includes('updateOpenIcon')
  const closeIconEnabled = planAbilities.includes('updateCloseIcon')
  const focusEnabled = planAbilities.includes('updateFocus')
  const avatarEnabled = planAbilities.includes('updateAvatar')
  const invertEnabled = planAbilities.includes('updateInvert')
  const iconShapeEnabled = planAbilities.includes('updateIconShape')

  return (
    <Card.Group>
      <Card fluid data-cf-tour="colour" style={!themeEnabled ? { ...disabledCard } : {}} className={!themeEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Theme{!themeEnabled && disabledLock}</Card.Header>
          <Card.Description>Pick the base colour used by the form.</Card.Description>
        </Card.Content>
        <Card.Content>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <input type="color" value={props.color} disabled={colorBusy | !themeEnabled} onChange={({ target: { value } }) => handleColorChange(value)} />
            <Button onClick={handleRandomColor} disabled={colorBusy | !themeEnabled} compact size='small' basic>I&apos;m Feeling Lucky</Button>
          </div>
        </Card.Content>
      </Card>

      <Card fluid data-cf-tour="icon" style={!openIconEnabled ? { ...disabledCard } : {}} className={!openIconEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Icon{!openIconEnabled && disabledLock}</Card.Header>
          <Card.Description>Pick the form trigger icon.</Card.Description>
        </Card.Content>

        <Card.Content>
          <Dropdown selection fluid disabled={iconBusy | !openIconEnabled} onChange={handleIconChange} options={iconOptions} value={props.icon} placeholder='Choose an option' />
        </Card.Content>
      </Card>

      <Card fluid style={!closeIconEnabled ? { ...disabledCard } : {}} className={!closeIconEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Close Icon{!closeIconEnabled && disabledLock}</Card.Header>
          <Card.Description>Pick the form close trigger icon.</Card.Description>
        </Card.Content>

        <Card.Content>
          <Dropdown selection fluid disabled={closeIconBusy | !closeIconEnabled} onChange={handleCloseIconChange} options={closeIconOptions} value={props.closeIcon} placeholder='Choose an option' />
        </Card.Content>
      </Card>

      <Card fluid style={!iconShapeEnabled ? { ...disabledCard } : {}} className={!iconShapeEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Icon Shape{!iconShapeEnabled && disabledLock}</Card.Header>
          <Card.Description>Prick the trigger icon shape.</Card.Description>
        </Card.Content>

        <Card.Content>
          <Button.Group size='large'>
            <Button basic onClick={() => handleIconShapeChange(0)} disabled={iconShapeBusy || props.iconShape === 0 || !iconShapeEnabled} loading={iconShapeBusy} icon='circle' active={props.iconShape === 0} />
            <Button basic onClick={() => handleIconShapeChange(1)} disabled={iconShapeBusy || props.iconShape === 1 || !iconShapeEnabled} loading={iconShapeBusy} icon='square' active={props.iconShape === 1} />
          </Button.Group>
        </Card.Content>
      </Card>

      <Card fluid style={!focusEnabled ? { ...disabledCard } : {}} className={!focusEnabled && 'disabledCardTooltip'}>
        <Card.Content>
          <Card.Header>Focus{!focusEnabled && disabledLock}</Card.Header>
          <Card.Description>Enable dark backdrop while form is active to increase focus.</Card.Description>
        </Card.Content>
        <Card.Content>
          <Checkbox toggle label='Enable' checked={props.blanket} disabled={blanketBusy | !focusEnabled} onClick={handleBlanketChange} />
        </Card.Content>
      </Card>

      <Card fluid style={!avatarEnabled ? { ...disabledCard } : {}} className={!avatarEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Avatar{!avatarEnabled && disabledLock}</Card.Header>
          <Card.Description>Upload your own avatar, such as a logo.</Card.Description>
        </Card.Content>

        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign='middle'>
                {!props.customAvatar
                  ? <>
                    <Button as="label" htmlFor="file" disabled={avatarBusy} loading={avatarBusy}>Choose Avatar</Button>
                    <input type="file" id="file" style={{ display: 'none' }} onChange={handleAvatarUpload} />
                    {uploadError
                      ? <Message negative size='tiny'>
                        <p>Unsupported image type, or image is too large.</p>
                      </Message>
                      : null}
                  </>
                  : <>
                    <Button basic compact disabled={avatarBusy | !avatarEnabled} loading={avatarBusy} onClick={handleAvatarRemove}>Remove Avatar</Button>
                  </>}
              </Grid.Column>
              <Grid.Column width={8} verticalAlign='middle'>
                <p style={{ fontSize: 'x-small' }}>png, jpg and gif supported up to 2mb.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>

      <Card fluid style={!invertEnabled ? { ...disabledCard } : {}} className={!invertEnabled ? 'disabledCardTooltip' : ''}>
        <Card.Content>
          <Card.Header>Invert{!invertEnabled && disabledLock}</Card.Header>
          <Card.Description>Enable inverted text and icon colours.</Card.Description>
        </Card.Content>
        <Card.Content>
          <Checkbox toggle label='Enable' checked={props.colorInvert} disabled={invertColorBusy | !invertEnabled} onClick={handleColorInvert} />
        </Card.Content>
      </Card>
      {abilities.abilitiesLoaded ? <UpgradeTooltip /> : null}
    </Card.Group>
  )
}

AppearanceCards.propTypes = {
  id: PropTypes.string,
  customAvatar: PropTypes.bool,
  color: PropTypes.string,
  colorInvert: PropTypes.bool,
  icon: PropTypes.number,
  closeIcon: PropTypes.number,
  loading: PropTypes.bool,
  blanket: PropTypes.bool,
  iconShape: PropTypes.number
}

export default AppearanceCards
