import ActionTypes from './action-types'
import http from './http'

export function createFormAPI (data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.POSTv2('v1/forms/create', { data }).then((response) => {
        dispatch({
          type: ActionTypes.FORM.CREATE,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function deleteFormAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.DELETEv2(`v1/forms/delete/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.DELETE,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function disableFormAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.PUTv2(`v1/forms/disable/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.DISABLE,
          payload: response
        })

        resolve(response)
      }).then(() => {
        dispatch(getFormAPI(id))
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getFormsAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/forms/get').then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_ALL,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getFormAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2(`v1/forms/getOne/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET,
          payload: response
        })

        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function putFormConfigAPI (id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ActionTypes.FORM.BUSY,
        payload: true
      })
      return http.PUTv2(`v1/forms/update/${id}`, { data }).then((response) => {
        dispatch({
          type: ActionTypes.FORM.UPDATE,
          payload: response
        })

        resolve(response)
      }).then(() => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        dispatch(getFormAPI(id))
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        reject(e)
      })
    })
  }
}

export function uploadAvatarAPI (id, image) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ActionTypes.FORM.BUSY,
        payload: true
      })

      const data = new FormData()
      data.append('avatar', image)
      return http.POSTv2(`v1/forms/set-profile/${id}`, {
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        dispatch({
          type: ActionTypes.FORM.AVATAR_SET,
          payload: response
        })

        resolve(response)
      }).then(() => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        dispatch(getFormAPI(id))
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        reject(e)
      })
    })
  }
}

export function getStatsAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/forms/stats').then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_STATS,
          payload: response
        })
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getAvailableFieldsAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/forms/fields/available').then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_AVAILBLE_FIELDS,
          payload: response
        })
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getFieldsLayoutAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2(`v1/forms/fields/layout/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_FIELDS_LAYOUT,
          payload: response
        })
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function updateFields (id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ActionTypes.FORM.BUSY,
        payload: true
      })
      return http.PUTv2(`v1/forms/fields/update/${id}`, { data }).then((response) => {
        dispatch({
          type: ActionTypes.FORM.UPDATE_FIELDS,
          payload: response
        })
        resolve(response)
      }).then(() => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        dispatch(getFormAPI(id))
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        reject(e)
      })
    })
  }
}

export function getAvailableEnticeTypesAPI () {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2('v1/forms/entice/available').then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_AVAILBLE_ENTICE_TYPES,
          payload: response
        })
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function getEnticeTypesAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return http.GETv2(`v1/forms/entice/types/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.GET_ENTICE_TYPES,
          payload: response
        })
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  }
}

export function updateEntice (id, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ActionTypes.FORM.BUSY,
        payload: true
      })
      return http.PUTv2(`v1/forms/entice/update/${id}`, { data }).then((response) => {
        dispatch({
          type: ActionTypes.FORM.UPDATE_ENTICE,
          payload: response
        })
        resolve(response)
      }).then(() => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        dispatch(getFormAPI(id))
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        reject(e)
      })
    })
  }
}

export function verifyFormDomainAPI (id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ActionTypes.FORM.BUSY,
        payload: true
      })
      return http.PUTv2(`v1/forms/verify/${id}`).then((response) => {
        dispatch({
          type: ActionTypes.FORM.VERIFY,
          payload: response
        })

        resolve(response)
      }).then(() => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        dispatch(getFormAPI(id))
      }).catch((e) => {
        dispatch({
          type: ActionTypes.FORM.BUSY,
          payload: false
        })
        reject(e)
      })
    })
  }
}
