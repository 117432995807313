export default {
  ACCOUNT: {
    UPDATE_USER_INFO: 'account_update_user_info',
    AUTH_LISTENER: 'account_auth_listener',
    SIGN_IN: 'account_sign_in',
    SIGN_OUT: 'account_sign_out',
    SUBSCRIPTION_GET: 'account_subscription_get',
    SUBSCRIPTION_NOT_FOUND: 'account_subscription_not_found',
    SUBSCRIPTION_ABILITIES_GET: 'account_subscription_abilities_get',
    BILLING_SESSION: 'account_billing_session',
    SUBSCRIPTION_DELETE: 'account_subscription_delete',
    SUBSCRIPTION_PAYMENT_GET: 'account_subscription_get_payment',
    SUBSCRIPTION_INVOICES_GET: 'account_subscription_get_invoices',
    EMAILS_GET: 'account_get_emails'
  },
  FORM: {
    CREATE: 'form_create',
    DELETE: 'form_delete',
    DISABLE: 'form_disable',
    GET_ALL: 'form_get_all',
    GET: 'form_get',
    UPDATE: 'form_update',
    AVATAR_SET: 'avatar_set',
    BUSY: 'form_busy',
    GET_STATS: 'form_get_stats',
    GET_FIELDS_LAYOUT: 'form_get_fields_layout',
    GET_AVAILBLE_FIELDS: 'form_get_available_fields',
    UPDATE_FIELDS: 'form_update_fields',
    GET_AVAILBLE_ENTICE_TYPES: 'form_get_available_entice_types',
    GET_ENTICE_TYPES: 'form_get_entice_types',
    UPDATE_ENTICE: 'form_update_entice',
    VERIFY: 'form_update_verify'
  },
  ASSETS: {
    GET_ICONS: 'assets_get_icons'
  }

}
