import React from 'react'
import styles from './input-char-counter.less'
import PropTypes from 'prop-types'
export const InputCharacterCount = function (props) {
  const { count, max } = props
  const remaining = max - count
  return <div className={styles.characterCounter}>
        <div>
            {remaining}
        </div>
    </div>
}

InputCharacterCount.propTypes = {
  count: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
}
